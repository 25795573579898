import React from 'react'
import classnames from 'classnames'

import { Rings } from 'react-loader-spinner'

export const AButton = ({
  icon,
  loadingIcon = <Rings type="Rings" color="white" height={24} width={24} />,
  overwriteStyles = false,
  isLoading,
  className = '',
  children,
  type = 'button',
  fullBorderRadius = false,
  primary = true,
  outline = false,
  small = false,
  disabled = false,
  warning = false,
  onClick = () => {},
}) => {
  const buttonClassNames = overwriteStyles
    ? className
    : `flex justify-center px-3 py-1.5 text-sm leading-6 rounded-full focus:outline-none ${
        primary && !outline && !warning
          ? 'font-semibold border-2 border-pelorous bg-pelorous text-white shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-pelorous focus:'
          : ''
      } ${
        outline
          ? 'font-semibold border-pelorous text-pelorous hover:text-white hover:bg-pelorous focus:ring-2 focus:ring-pelorous focus:ring-offset-2 focus:text-white focus:bg-pelorous'
          : ''
      } ${outline && (small ? 'border' : 'border-2')} ${
        warning ? 'font-semibold text-white focus:outline-none border-red-400 text-red-400' : ''
      } ${className} ${disabled && 'opacity-50'}`

  return (
    <button
      onClick={!isLoading ? onClick : undefined}
      type={type}
      className={classnames(buttonClassNames, { 'bg-opacity-50 pointer-events-none': isLoading })}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <>{Boolean(loadingIcon) && loadingIcon}</>
      ) : (
        <>
          {Boolean(icon) && <div className="mr-2">{icon}</div>}
          <span className="text-center w-full inline-block">{children}</span>
        </>
      )}
    </button>
  )
}
