import { useCallback, useEffect, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useGoalProvider } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'

export const MChatGoal = ({
  title = '',
  description = '',
  remindersTemplates = [],
  onProceed,
  templateId,
  ...rest
}) => {
  const { onGoal = () => {}, doesGoalExist } = useGoalProvider()

  const isFinishedRef = useRef(false)

  const areGoalAlreadyExist = useMemo(() => {
    return doesGoalExist(templateId)
  }, [doesGoalExist, templateId])

  const goalData = useMemo(() => {
    return {
      templateId,
      title,
      description,
      remindersTemplates,
      ...rest,
    }
  }, [description, remindersTemplates, rest, templateId, title])

  const handleOnProceed = useCallback(() => {
    onGoal(goalData)
    isFinishedRef.current = true

    onProceed()
  }, [goalData, onGoal, onProceed])

  useEffect(() => {
    if (isFinishedRef.current || !areGoalAlreadyExist) {
      return
    }

    isFinishedRef.current = true
    setTimeout(onProceed, 50)
  }, [areGoalAlreadyExist, onProceed])

  return (
    <div className="py-3 px-3.5 lg:px-5">
      {title && <h4 className="text-2xl mb-1.5">{title}</h4>}
      {description && <p className="mb-3">{description}</p>}
      {areGoalAlreadyExist ? (
        <div className="mt-px py-2 text-center uppercase font-medium">Goal already exists</div>
      ) : (
        <>
          <div
            className={classnames(
              'mb-5',
              (title || description) && remindersTemplates.length && 'mt-8 border-t border-white'
            )}
          >
            {remindersTemplates.map(({ id, title, time }) => (
              <div key={id} className="py-2 text-sm tracking-tight border-b border-white">
                {title} <span className="font-bold">@ {time}</span>
              </div>
            ))}
          </div>
          {isFinishedRef.current ? (
            <div className="mt-px py-2 text-center uppercase font-medium">Confirmed</div>
          ) : (
            <AButton small onClick={handleOnProceed} className="block mx-auto">
              Confirm
            </AButton>
          )}
        </>
      )}
    </div>
  )
}
