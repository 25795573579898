import React, { useContext, createContext } from 'react'

export const UserContext = createContext({})
UserContext.displayName = 'UserContext'

export const UserProvider = ({
  children,
  user = {
    firstName: '',
    lastName: '',
  },
}) => {
  const generateInitials = () => {
    const splittedLastNames = user.lastName.split(' ')

    return user.firstName.slice(0, 1) + splittedLastNames.pop().slice(0, 1)
  }

  return (
    <UserContext.Provider value={{ user: { ...user, initials: generateInitials() } }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be nested in UserProvider')
  }

  return context
}
