import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { resetPassword } from '@services/Authentication'

import { useTranslations } from '@hooks'

import { AButton } from '@atoms/AButton'
import { AFormInput } from '@atoms/AFormInput'
import { OModal } from './OModal'
import { trackEvent } from '@services/Analytics'
import ANALYTICS from '@utils/constants/analytics'

export const OResetPasswordModal = ({ email, isOpen, ...props }) => {
  const { t } = useTranslations()
  const [isWorking, setIsWorking] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [wasEmailSent, setWasEmailSent] = useState(false)

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: email,
    },
  })

  const handleResetPassword = useCallback(async email => {
    try {
      setIsWorking(true)
      setErrorMsg(false)
      await resetPassword(email)
      setIsWorking(false)
      setWasEmailSent(true)
      trackEvent(ANALYTICS.RESET_PASSWORD)
    } catch (err) {
      setErrorMsg(t('error.email'))
      setIsWorking(false)
    }
  }, [])

  const onSubmit = useCallback(
    data => {
      handleResetPassword(data.email || email)
    },
    [email, handleResetPassword]
  )

  useEffect(() => {
    if (!isOpen) {
      setIsWorking(false)
      setErrorMsg(null)
      setWasEmailSent(false)
      setErrorMsg(false)
      reset()
    }
  }, [isOpen, reset])

  return (
    <OModal isOpen={isOpen} {...props}>
      {wasEmailSent ? (
        <div className="py-6 text-center">
          {t('modal.confirmResetPassword1')}
          <br />
          {t('modal.confirmResetPassword2')}
        </div>
      ) : (
        <>
          <div className="max-w-xs mx-auto text-center">
            <h2 className="text-lg font-semibold">{t('modal.resetPassword')}</h2>
            <div className="mt-1 w-full flex flex-col mb-6 justify-center items-center">
              {email ? (
                <p className="text-sm">{t('modal.resetPasswordInstruction')}</p>
              ) : (
                <p className="text-sm">{t('modal.enterEmail')}</p>
              )}
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {!email && (
              <Controller
                control={control}
                name="email"
                rules={{ required: t('required.email') }}
                render={({ field, fieldState }) => (
                  <AFormInput
                    {...field}
                    id="email"
                    type="email"
                    placeholder={t('label.email')}
                    autoComplete="email"
                    errorMessage={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            )}

            <div className="w-full mt-4">
              <AButton type="submit" isLoading={isWorking} className="w-full">
                {email ? t('resetLink') : t('submit')}
              </AButton>
            </div>
          </form>
        </>
      )}

      {errorMsg && (
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm leading-5">
              <span className="px-2 bg-white text-gray-light">{t('error.error')}</span>
            </div>
          </div>

          <div className="mt-2 flex flex-1 flex-grow text-center">
            <p className="text-red-600 text-sm mx-auto">{errorMsg}</p>
          </div>
        </div>
      )}
    </OModal>
  )
}

OResetPasswordModal.propTypes = {
  email: PropTypes.string,
}
