import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import { CheckCircle } from 'react-feather'
import ReactMarkdown from 'react-markdown'
import { Switch } from '@headlessui/react'
import { AButton } from '../atoms/AButton'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatOathChecklist = ({
  title,
  oaths,
  textBeforeOaths,
  first,
  onProceed = () => {},
}) => {
  const { user } = useUser()
  const [checkedOaths, setCheckedOaths] = useState(oaths.map(() => false))
  const [areAllOathsChecked, setAreAllOathsChecked] = useState(false)
  const [isOathed, setIsOathed] = useState(false)

  const toggleOathAtIndex = useCallback(
    index => {
      const newCheckedOaths = [...checkedOaths]
      newCheckedOaths[index] = !newCheckedOaths[index]
      const newAreAllOathsChecked = !newCheckedOaths.includes(false)

      setCheckedOaths(newCheckedOaths)
      setAreAllOathsChecked(newAreAllOathsChecked)
    },
    [checkedOaths]
  )

  const handleOnProceed = () => {
    if (areAllOathsChecked) {
      setIsOathed(true)
      onProceed()
    } else {
      alert('Please check all options first')
    }
  }

  return (
    <div
      className={classnames(
        'py-3 px-3.5 lg:px-5 border transition-all duration-300',
        areAllOathsChecked
          ? 'border-stone-200/40 bg-stone-200/40'
          : 'border-persianRed text-gray-50 bg-pelorous'
      )}
    >
      <div className="flex flex-col items-center">
        {title && <h4 className="text-lg mb-3">{title}</h4>}
        {textBeforeOaths && <p className="mb-3">{textBeforeOaths}</p>}
        <ul>
          {oaths.map((oath, index) => (
            <li key={index} className="mb-4">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={checkedOaths[index]}
                  onChange={() => {
                    toggleOathAtIndex(index)
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      toggleOathAtIndex(index)
                    }
                  }}
                  disabled={isOathed}
                  className={classnames(
                    checkedOaths[index] ? 'bg-pelorous' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pelorous'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classnames(
                      checkedOaths[index] ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-gray-50 shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <ReactMarkdown className="text-sm font-medium">
                    {_.template(oath)({ user })}
                  </ReactMarkdown>
                </Switch.Label>
              </Switch.Group>
            </li>
          ))}
        </ul>
        {!isOathed ? (
          <AButton
            small
            className={classnames('text-gray-900')}
            disabled={!areAllOathsChecked}
            outline={!areAllOathsChecked}
            onClick={handleOnProceed}
          >
            Confirm
          </AButton>
        ) : (
          <div className="flex items-center justify-center py-2">
            <CheckCircle size={20} />
            <span className="ml-2 pt-px uppercase font-medium">Confirmed</span>
          </div>
        )}
      </div>
    </div>
  )
}

MChatOathChecklist.propTypes = {
  title: PropTypes.string,
  textBeforeOaths: PropTypes.string,
  oaths: PropTypes.array,
  onProceed: PropTypes.func.isRequired,
  first: PropTypes.bool,
}
