import { useCallback, useState } from 'react'

import { FileText } from 'react-feather'
import { ACloseIcon } from '../atoms/ACloseIcon'
import { MChatModal } from './MChatModal'
import { AMarkdown } from '../atoms/AMarkdown'

export const MChatAudioTranscript = ({ transcriptMarkdown }) => {
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false)

  const openTranscript = useCallback(() => {
    setIsTranscriptModalOpen(true)
  }, [])

  const closeTranscript = useCallback(() => {
    setIsTranscriptModalOpen(false)
  }, [])

  return (
    <div>
      <div
        onClick={openTranscript}
        className="flex justify-center items-center py-1 px-2.5 border border-white rounded-full cursor-pointer"
      >
        <FileText size={16} color="white" />
        <div className="ml-0.5 text-sm text-white">Transcript</div>
      </div>
      <MChatModal isOpen={isTranscriptModalOpen} onClose={closeTranscript}>
        <div className="max-w-lg pt-10 px-6 pb-6 bg-mirage rounded-lg overflow-hidden">
          <div className="absolute top-2 right-2 flex">
            <ACloseIcon iconName="x" onClick={closeTranscript} />
          </div>
          <div className="max-h-[300px] pr-1 overflow-y-auto">
            <AMarkdown>{transcriptMarkdown}</AMarkdown>
          </div>
        </div>
      </MChatModal>
    </div>
  )
}
