import React, { useCallback, useState } from 'react'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { useForm, Controller } from 'react-hook-form'
import { signIn } from '@services/Authentication'

import { useTranslations } from '@hooks'

import logo from '@assets/images/logo-big.svg'

import { AButton } from '@atoms/AButton'
import { AFormInput } from '@atoms/AFormInput'
import { OResetPasswordModal } from '@organisms/OResetPasswordModal'

export const PSignIn = () => {
  const { t } = useTranslations()

  const [isWorking, setIsWorking] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const handleSignIn = useCallback(async (email, password) => {
    try {
      setIsWorking(true)
      setErrorMsg(false)
      await signIn({ email, password })
      setIsWorking(false)
    } catch (err) {
      setErrorMsg(t('error.signIn'))
      setIsWorking(false)
    }
  }, [])

  const onSubmit = useCallback(
    data => {
      handleSignIn(data.email, data.password)
    },
    [handleSignIn]
  )

  const handleOpenResetPasswordModal = useCallback(() => {
    setIsResetPasswordModalOpen(true)
  }, [])

  const handleCloseResetPasswordModal = useCallback(() => {
    setIsResetPasswordModalOpen(false)
  }, [])

  return (
    <OPageWrapper
      isHeaderVisible={false}
      contentClassName="flex flex-col items-center justify-between min-h-screen py-12"
    >
      <div className="mt-10 md:mt-16 flex flex-col items-center max-w-[300px]">
        <div className="mx-auto">
          <img src={logo} alt="Logo" />
        </div>
        <h2 className="mt-14 md:mt-16 text-center text-3xl font-bold">{t('welcome')}</h2>
        <p className="mt-4 text-center text-base">{t('getStarted')}</p>
        <form className="w-full mt-4 space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            rules={{ required: t('required.email') }}
            render={({ field, fieldState }) => (
              <AFormInput
                {...field}
                id="email"
                placeholder={t('label.email')}
                type="email"
                autoComplete="email"
                border={false}
                errorMessage={fieldState.error && fieldState.error.message}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: t('required.password') }}
            render={({ field, fieldState }) => (
              <AFormInput
                {...field}
                id="password"
                placeholder={t('label.password')}
                type="password"
                autoComplete="current-password"
                border={false}
                errorMessage={fieldState.error && fieldState.error.message}
              />
            )}
          />

          <div className="pt-3 mx-8">
            <AButton className="w-full" type="submit" isLoading={isWorking}>
              {t('signIn')}
            </AButton>
          </div>
        </form>
        <div className="mt-6">
          <AButton onClick={handleOpenResetPasswordModal} outline small className="mx-auto">
            {t('resetPassword')}
          </AButton>
        </div>
        <div>
          {errorMsg && (
            <div className="mt-6 flex flex-1 flex-grow text-center">
              <p className="text-red-600 text-sm mx-auto">{errorMsg}</p>
            </div>
          )}
        </div>
      </div>

      <OResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onClose={handleCloseResetPasswordModal}
      />
    </OPageWrapper>
  )
}
