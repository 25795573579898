import mixpanel from 'mixpanel-browser'

import Config from '@config/Config'

let isInitialized = false

export const initializeAnalytics = async () => {
  if (!Config.analyticsEnabled) return

  mixpanel.init(Config.mixpanel.token)
  isInitialized = true
}

// INFO: assign userID and other user params
export const identifyUser = (userId, options) => {
  // console.log('identifyUser', { userId, options })

  if (!isInitialized) {
    initializeAnalytics()
    return
  }
  if (userId) {
    mixpanel.identify(userId)
    if (options) {
      mixpanel.people.set(options)
    }
  } else {
    mixpanel.reset()
  }
}

// INFO: track event
export const trackEvent = (event, options) => {
  if (!isInitialized) {
    initializeAnalytics()
    return
  }

  if (options) {
    // console.log('trackEvent', event, options)
    mixpanel.track(event, options)
  } else {
    // console.log('trackEvent', event)
    mixpanel.track(event)
  }
}
