import { MSkeleton } from '@molecules/MSkeleton'

export const OTopicSkeleton = () => (
  <div>
    <MSkeleton width={160} height={26} />
    <div className="flex mt-4 -mr-3 sm:-mr-5 lg:mr-0 mb-6 md:mb-10 overflow-hidden">
      {[...Array(3).keys()].map(item => (
        <div
          key={item}
          className="last:lg:hidden flex flex-col justify-between w-44 md:w-48 h-64 md:h-72 mr-2 last:mr-0 pt-2.5 px-2.5 pb-7 bg-white rounded-lg !rounded-tl-none"
        >
          <div className="ml-auto">
            <MSkeleton height={22} width={22} rounded="full" />
          </div>
          <div className="mx-auto">
            <MSkeleton height={22} width={150} />
            <div className="mt-3 mx-auto">
              <MSkeleton height={14} width={80} />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)
