import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { AFormInputWrapper } from '@atoms/AFormInputWrapper'

export const AFormInput = React.forwardRef(
  ({ label, id, name, disabled, className, errorMessage, border = true, ...props }, ref) => {
    return (
      <AFormInputWrapper label={label} id={id} name={name} errorMessage={errorMessage}>
        <input
          id={id}
          name={name}
          disabled={disabled}
          ref={ref}
          className={classnames(
            'mt-1 form-input block w-full py-2 px-3 rounded focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-white disabled:text-gray-light',
            {
              [className]: true,
              'border border-gray-300': border,
              'border-gray-300 focus:shadow-outline-pelorous focus:border-pelorous': !errorMessage,
              'border-red-400': !!errorMessage,
            }
          )}
          {...props}
        />
      </AFormInputWrapper>
    )
  }
)

AFormInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
}
