import { useCallback, useEffect, useState } from 'react'

import { OModal } from '@organisms/OModal'

export const MPastFinishedGoalsModal = ({
  finishedGoals,
  onClose = () => {},
  onShow = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(!!finishedGoals?.length)
  }, [finishedGoals])

  const onRequestClose = useCallback(() => {
    setIsOpen(false)
    onClose(finishedGoals)
  }, [finishedGoals, onClose])

  useEffect(() => {
    if (isOpen) {
      onShow(finishedGoals)
    }
  }, [finishedGoals, isOpen, onShow])

  return (
    <OModal isOpen={isOpen} title="Finished Goals" onClose={onRequestClose}>
      {finishedGoals?.map(lastFinishedGoal => (
        <div key={lastFinishedGoal?.goal?.id} className="mt-3 pb-0.5">
          <div>{lastFinishedGoal?.goal?.title}</div>
          <div className="text-sm text-gray-600">id: {lastFinishedGoal?.goal?.id}</div>
        </div>
      ))}
    </OModal>
  )
}
