import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import { Switch } from '@headlessui/react'
import { AButton } from '../atoms/AButton'
import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatInteractiveChecklist = ({
  title,
  choices,
  textBeforeOaths,
  multipleSelection = false,
  triggers,
  onProceed = () => {},
}) => {
  const { user } = useUser()
  const [checkedChoices, setCheckedChoices] = useState(choices.map(() => false))
  const [isFinished, setIsFinished] = useState(false)
  const [isAtLeastOneSelected, setIsAtLeastOneSelected] = useState(false)

  useEffect(() => {
    setIsAtLeastOneSelected(Object.values(checkedChoices).some(value => !!value))
  }, [checkedChoices])

  const toggleChoiceAtIndex = index => {
    const newcheckedChoices = multipleSelection
      ? checkedChoices.map(value => value)
      : [false, false, false]
    newcheckedChoices[index] = !newcheckedChoices[index]

    setCheckedChoices(newcheckedChoices)
  }

  const destinationTrigger = () => {
    const selectedFootprint = Object.entries(checkedChoices)
      .filter(([, value]) => value)
      .map(([id]) => id)
      .sort()
      .join(',')

    return triggers[selectedFootprint] || triggers.else || triggers
  }

  const handleOnProceed = () => {
    if (isAtLeastOneSelected) {
      onProceed(destinationTrigger())
      setIsFinished(true)
    } else {
      alert('Select something first')
    }
  }

  return (
    <div className="flex flex-col py-3 px-3.5 lg:px-5">
      {title && <h4 className="text-lg mb-3">{title}</h4>}
      {textBeforeOaths && <p className="mb-3">{textBeforeOaths}</p>}
      <ul>
        {choices.map((oath, index) => (
          <li key={index} className="mb-4">
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={checkedChoices[index]}
                onChange={() => {
                  toggleChoiceAtIndex(index)
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    toggleChoiceAtIndex(index)
                  }
                }}
                disabled={isFinished}
                className={classnames(
                  checkedChoices[index] ? 'bg-pelorous' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pelorous'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classnames(
                    checkedChoices[index] ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-gray-50 shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <AMarkdown className="text-sm font-medium">{_.template(oath)({ user })}</AMarkdown>
              </Switch.Label>
            </Switch.Group>
          </li>
        ))}
      </ul>
      <div className="mt-2 flex justify-center">
        {!isFinished && (
          <AButton
            disabled={!isAtLeastOneSelected}
            outline={!isAtLeastOneSelected}
            onClick={handleOnProceed}
          >
            Proceed
          </AButton>
        )}
      </div>
    </div>
  )
}

MChatInteractiveChecklist.propTypes = {
  title: PropTypes.string,
  textBeforeOaths: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  onProceed: PropTypes.func.isRequired,
  multipleSelection: PropTypes.bool,
}
