import React from 'react'
import _ from 'lodash'
import { useUser } from '@edwin/react-sdk'

import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatBotMsg = ({ msg }) => {
  const { user } = useUser()

  return (
    <div className="py-3 px-3.5 lg:px-5">
      <AMarkdown>{_.template(msg)({ user })}</AMarkdown>
    </div>
  )
}
