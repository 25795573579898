import React from 'react'
import PropTypes from 'prop-types'

import { ChevronLeft } from 'react-feather'

export const OMissionDetailsHeader = ({ title, onClose, shouldDisplayCloseIcon = true }) => (
  <div className="flex items-center py-4 px-3 bg-white shadow-md">
    {shouldDisplayCloseIcon && (
      <button
        onClick={onClose}
        className="flex items-center justify-center flex-shrink-0 flex-grow-0 cursor-pointer mr-2 rounded-sm focus:outline-none focus:ring-2 focus:ring-pelorous focus:ring-offset-2"
      >
        <ChevronLeft size={18} />
      </button>
    )}
    <h4 className="flex-1 text-sm font-semibold truncate">{title}</h4>
  </div>
)

OMissionDetailsHeader.propTypes = {
  actionLabel: PropTypes.string,
  onClose: PropTypes.func,
}
