import React, { forwardRef } from 'react'
import { getSanityImage } from '@edwin/react-sdk'
import classnames from 'classnames'

import { useTranslations } from '@hooks'

import ProgressiveImage from 'react-progressive-graceful-image'
import { AIconCompleted, AIconLocked } from '@atoms/AIcons'

export const OMissionCover = forwardRef(({ mission, onClick = () => {}, className }, ref) => {
  const { t } = useTranslations()
  const { coverImageUrl, coverImageMeta, isCurrent, isCompleted } = mission
  const isLocked = !isCompleted && !isCurrent

  const placeholderImage = getSanityImage(coverImageUrl, {
    width: 252,
    height: 384,
    crop: coverImageMeta?.crop,
    hotspot: coverImageMeta?.hotspot,
    quality: 30,
  })

  const coverImage = getSanityImage(coverImageUrl, {
    width: 252 * 3,
    height: 384 * 3,
    crop: coverImageMeta?.crop,
    hotspot: coverImageMeta?.hotspot,
  })

  return (
    <button
      ref={ref}
      onClick={isLocked ? undefined : onClick}
      tabIndex={isLocked ? -1 : 0}
      className={classnames(
        'relative flex items-end rounded-lg !rounded-tl-none overflow-hidden transition-opacity',
        'my-1 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-pelorous',
        isLocked ? 'cursor-auto pointer-events-none' : 'hover:opacity-90 cursor-pointer',
        className
      )}
    >
      <div className="absolute inset-0">
        {coverImage && (
          <ProgressiveImage placeholder={placeholderImage} src={coverImage}>
            {src => <img className="w-full h-full object-cover" src={src} alt="mission cover" />}
          </ProgressiveImage>
        )}
      </div>
      <div className="absolute inset-0 bg-cover" />
      <div className="absolute top-2 right-2 flex flex-col">
        {isCompleted && <AIconCompleted className="w-7 h-7 mb-1.5 bg-citron opacity-80" />}
        {isLocked && <AIconLocked className="w-7 h-7 bg-gray-900 text-gray-50 opacity-80" />}
      </div>
      <div
        className={classnames(
          'relative w-full px-1 text-gray-50 text-center',
          isCurrent ? 'pb-6' : 'pb-3'
        )}
      >
        <h4 className={classnames('text-xl md:text-2xl font-medium', isLocked && 'opacity-50')}>
          {mission.title}
        </h4>
        <div
          className={classnames(
            'h-0.5 mx-auto bg-gray-50 rounded-full',
            isCurrent ? 'w-8 md:w-9 mt-5 md:mt-6 mb-3.5 md:mb-4' : 'w-6 md:w-7 my-3 md:my-3.5'
          )}
        />
        <div className="text-xs uppercase">
          {t('points', { number: mission.pointsForCompletion })}
        </div>
      </div>
    </button>
  )
})
