import { MSkeleton } from '@molecules/MSkeleton'

export const OAccountSkeleton = () => (
  <div>
    <MSkeleton height={40} width={160} />
    <div className="mt-3 -mx-3 sm:mx-0 space-y-3 sm:max-w-lg">
      <div className="mb-1.5 py-2.5 px-4 bg-white sm:rounded-lg">
        <MSkeleton width={50} height={14} />
        <div className="mb-2" />
        <MSkeleton width={200} height={18} />
        <div className="mb-1" />
      </div>
      <div className="mb-1.5 py-2.5 px-4 bg-white sm:rounded-lg">
        <MSkeleton width={70} height={14} />
        <div className="mb-2" />
        <MSkeleton width={80} height={18} />
        <div className="mb-1" />
      </div>
      <div className="mb-1.5 py-2.5 px-4 bg-white sm:rounded-lg">
        <MSkeleton width={50} height={14} />
        <div className="mb-2" />
        <MSkeleton width={100} height={18} />
        <div className="mb-1" />
      </div>
      <div className="mb-1.5 py-2.5 px-4 bg-white sm:rounded-lg">
        <MSkeleton width={75} height={14} />
        <div className="mb-2" />
        <MSkeleton width={150} height={37} />
        <div className="mb-1" />
      </div>
    </div>
  </div>
)
