import { useEffect } from 'react'
import { useIsOnline } from '@hooks/useIsOnline'

import useProgressStore from '@store/useProgressStore'

export const useProgressQueue = () => {
  const isOnline = useIsOnline()

  const processQueue = useProgressStore(state => state.processQueue)
  const requestsQueue = useProgressStore(state => state.requestsQueue)

  useEffect(() => {
    if (isOnline) {
      processQueue()
    }
  }, [processQueue, requestsQueue, isOnline])
}
