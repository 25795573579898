export const translations = {
  en: {
    translation: {
      topicHeading: 'Continue on {{topicName}}',
      progressHeading: 'Program Progress',
      leaderboardHeading: '{{teamName}} Leaderboard',
      points: '{{number}} points',
      navigation: {
        today: 'Today',
        progress: 'Progress',
        messages: 'Messages',
        account: 'Account',
      },
      screen: {
        today: 'Today',
        progress: 'Program progress',
        leaderboard: 'Leaderboard',
        messages: 'Messages',
        conversation: 'Conversation',
        account: 'Your Account',
        gettingStarted: 'Getting started',
      },
      label: {
        name: 'Name',
        email: 'Email',
        password: 'Password',
        language: 'Language',
        points: 'My points',
        totalPoints: 'My total points',
        weeks_one: 'Week',
        weeks_other: 'Weeks',
        assignments_one: 'Assignment',
        assignments_other: 'Assignments',
        notifications: 'Allow Notifications',
      },
      required: {
        email: 'Email is required',
        password: 'Password is required',
      },
      error: {
        error: 'Error',
        signIn: 'Failed to sign in. Please check your email and password, and try again.',
        email: "We can't find that email. Please double-check.",
        noData: 'No data',
        somethingWrong: 'Something went wrong. Please, try again',
        internetConnection: 'There is no internet connection',
      },
      chat: {
        with: 'with ',
      },
      mission: {
        author: 'with ',
        steps: "What we'll cover",
      },
      modal: {
        congratulations: 'Congratulations!',
        bonusPoints: '{{points}} Bonus points!',
        completedTopic: 'You have completed {{topicName}}.',
        resetPassword: 'Reset your password',
        resetPasswordInstruction:
          'To reset your password press the button below and check you email inbox for instructions from us on how to reset your password',
        resetPasswordDescription:
          'Enter your email and we will send you a link to reset your password.',
        passwordWasSent: 'Password reset link was sent',
        enterEmail: 'Enter your email and we will send you a link to reset your password',
        confirmResetPassword1: 'It should be in your inbox shortly.',
        confirmResetPassword2: "If you can't find it, check your SPAM folder.",
        areYouSure: 'Are you sure?',
        startFromScratch: 'You will need to start this assignment from scratch',
        signOut: 'Are you sure you want to sign out?',
        notifications:
          'To {{isNotificationsEnabled}} Push Notifications go to Settings and {{isNotificationsEnabled}} notifications for MAX Pro.',
        pushNotifications: 'Push Notifications',
      },
      summaryComponent: {
        congrats: 'Congratulations!',
        youEarned: 'You earned',
        points: '{{pointsForCompletion}} points.',
        totalPoints: '{{summaryPoints}} total points',
        ctaText: 'Finish',
      },
      assignments: 'Assignments',
      welcome: 'Welcome!',
      getStarted: 'Sign in and get started with Max Sports.',
      yourAccount: 'Your Account',
      signIn: 'Sign in',
      signOut: 'Sign out',
      resetPassword: 'Reset Password',
      resetLink: 'Get reset link',
      retry: 'Retry',
      submit: 'Submit',
      confirm: 'Confirm',
      cancel: 'Cancel',
      openSettings: 'Open Settings App',
      version: 'Version: {{appVersion}}',
      screenReaders: {
        openMainMenu: 'Open main menu',
        openUserMenu: 'Open user menu',
        closePanel: 'Close panel',
      },
    },
  },

  es: {
    translation: {
      topicHeading: 'Continuar {{topicName}}',
      progressHeading: 'Progreso del programa',
      leaderboardHeading: '{{teamName}} Clasificación',
      points: '{{number}} puntos',
      navigation: {
        today: 'Hoy',
        progress: 'Progreso',
        messages: 'Mensajes',
        account: 'Cuenta',
      },
      screen: {
        today: 'Hoy',
        progress: 'Progreso del programa',
        leaderboard: 'Clasificación',
        messages: 'Mensajes',
        conversation: 'Conversación',
        account: 'Su cuenta',
        gettingStarted: 'Cómo empezar',
      },
      label: {
        name: 'Nombre',
        email: 'Correo electrónico',
        password: 'Contraseña',
        language: 'Idioma',
        points: 'Mis puntos',
        totalPoints: 'Mis puntos totales',
        weeks_one: 'Semana',
        weeks_other: 'Semanas',
        assignments_one: 'Asignación',
        assignments_other: 'Asignaciones',
        notifications: 'Permitir notificaciones',
      },
      required: {
        email: 'Correo electrónico obligatorio',
        password: 'Se requiere contraseña',
      },
      error: {
        error: 'Error',
        signIn:
          'No se ha podido iniciar sesión. Compruebe su correo electrónico y contraseña e inténtelo de nuevo.',
        email: 'No podemos encontrar ese correo electrónico. Por favor, vuelva a comprobarlo.',
        noData: 'Sin datos',
        somethingWrong: 'Algo ha ido mal. Por favor, inténtelo de nuevo.',
        internetConnection: 'No hay conexión a Internet',
      },
      chat: {
        with: 'con ',
      },
      mission: {
        author: 'con ',
        steps: 'Qué trataremos',
      },
      modal: {
        congratulations: '¡Felicidades!',
        bonusPoints: '{{points}} ¡Puntos extra!',
        completedTopic: 'Ha completado {{topicName}}.',
        resetPassword: 'Restablecer contraseña',
        resetPasswordInstruction:
          'Para restablecer su contraseña, pulse el botón que aparece a continuación y consulte su buzón de correo electrónico para recibir instrucciones sobre cómo restablecer su contraseña.',
        resetPasswordDescription:
          'Introduzca su correo electrónico y le enviaremos un enlace para restablecer su contraseña.',
        passwordWasSent: 'Se ha enviado el enlace para restablecer la contraseña',
        enterEmail:
          'Introduce tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña',
        confirmResetPassword1: 'En breve lo recibirá en su bandeja de entrada.',
        confirmResetPassword2: 'Si no lo encuentras, comprueba tu carpeta de SPAM.',
        areYouSure: '¿Seguro?',
        startFromScratch: 'Tendrás que empezar esta tarea desde cero',
        signOut: '¿Seguro que quieres cerrar la sesión?',
        notifications:
          'Para {{isNotificationsEnabled}} notificaciones Push ir a Configuración y {{isNotificationsEnabled}} notificaciones para MAX Pro.',
        pushNotifications: 'Notificaciones Push',
      },
      summaryComponent: {
        congrats: '¡Felicidades!',
        youEarned: 'Has ganado',
        points: '{{pointsForCompletion}} puntos.',
        totalPoints: '{{summaryPoints}} puntos totales',
        ctaText: 'Acabado',
      },
      assignments: 'Asignaciones',
      welcome: '¡Bienvenido!',
      getStarted: 'Regístrate y empieza a utilizar Max Sports.',
      yourAccount: 'Su cuenta',
      signIn: 'Iniciar sesión',
      signOut: 'Cerrar sesión',
      resetPassword: 'Restablecer contraseña',
      resetLink: 'Obtener enlace de reinicio',
      retry: 'Reintentar',
      submit: 'Enviar',
      confirm: 'Confirme',
      cancel: 'Cancelar',
      openSettings: 'Abrir la aplicación Ajustes',
      version: 'Versión: {{appVersion}}',
      screenReaders: {
        openMainMenu: 'Abrir el menú principal',
        openUserMenu: 'Abrir el menú de usuario',
        closePanel: 'Cerrar panel',
      },
    },
  },
}
