import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getSanityImage, useAuthor } from '@edwin/react-sdk'

import ProgressiveImage from 'react-progressive-graceful-image'
import { ACloseIcon } from '../atoms/ACloseIcon'

export const OMissionDetailsSidebar = ({
  title = '',
  imageUrl,
  imageMeta,
  steps,
  activeStep = null,
  translations,
  shouldDisplayCloseIcon = true,
  onClose = () => {},
}) => {
  const { author } = useAuthor()

  const textTranslations = {
    author: 'with ',
    steps: "What we'll cover",
    ...translations,
  }

  return (
    <div className="hidden lg:block w-[300px]">
      <div className="fixed top-0 w-[300px] h-screen text-gray-50 bg-pelorous shadow-xl overflow-hidden">
        {shouldDisplayCloseIcon && (
          <div className="relative">
            <div className="absolute top-2 left-2 z-40">
              <ACloseIcon onClick={onClose} />
            </div>
          </div>
        )}
        {imageUrl && (
          <ProgressiveImage
            placeholder={getSanityImage(imageUrl, {
              width: 100,
              height: 100,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
              quality: 20,
            })}
            src={getSanityImage(imageUrl, {
              width: 600,
              height: 600,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
            })}
          >
            {src => <img className="w-full h-72 object-cover m-0 p-0" src={src} alt="Author" />}
          </ProgressiveImage>
        )}
        <div className={classnames('p-7', { 'pt-14': !imageUrl && shouldDisplayCloseIcon })}>
          {title && (
            <h2 className={classnames('text-xl font-bold', author?.name ? 'mb-2' : 'mb-5')}>
              {title}
            </h2>
          )}
          {author?.name && (
            <h5 className="mb-5">
              {textTranslations.author}
              {author?.name}
            </h5>
          )}
          {steps?.length && (
            <>
              <svg
                width="119"
                height="10"
                viewBox="0 0 119 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39.3262 1C49.3096 1 49.3096 8.66095 59.2446 8.66095C69.2281 8.66095 69.2281 1 79.1631 1C89.1466 1 89.1466 8.66095 99.0816 8.66095C109.017 8.66095 109.065 1 119 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M0 1C9.84349 1 9.84349 8.66095 19.6392 8.66095C29.4827 8.66095 29.4827 1 39.3262 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>

              <h5 className="mt-5 text-xs font-normal uppercase mb-3 tracking-wider">
                {textTranslations.steps}
              </h5>
              {steps.map((step, index) => (
                <div
                  key={step}
                  className={classnames(
                    'mb-2.5 py-2.5 px-4 bg-gray-50 bg-opacity-10 rounded-lg rounded-tl-none transition-colors duration-300',
                    activeStep <= index ? 'bg-opacity-10' : 'text-darkMirage bg-opacity-90'
                  )}
                >
                  <p className="text-sm">{step}</p>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

OMissionDetailsSidebar.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
  imageUrl: PropTypes.string,
  imageMeta: PropTypes.object,
  onClose: PropTypes.func,
  authorName: PropTypes.string,
  activeStep: PropTypes.number,
}
