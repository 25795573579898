import React, { useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import useChatsStore from '@store/useChatStore'

/* global CometChatWidget */ // To disable any eslint 'CometChatWidget not defined' errors
export const OChatWidget = ({ chatCredentials }) => {
  const isChatInitialized = useRef(false)
  const isChatInitializing = useRef(false)

  const refreshChatUnreadMessageCount = useChatsStore(state => state.refreshChatUnreadMessageCount)

  const handleInitializeChatWidget = useCallback(async () => {
    if (chatCredentials && !isChatInitialized.current && !isChatInitializing.current) {
      isChatInitializing.current = true

      await CometChatWidget.init({
        appID: chatCredentials.appId,
        appRegion: chatCredentials.region,
        authKey: chatCredentials.authKey,
      })

      await CometChatWidget.login({
        uid: chatCredentials.uid,
      })

      if (!isChatInitialized.current) {
        await CometChatWidget.launch({
          widgetID: chatCredentials.widgetId,
          target: '#cometchat',
          defaultType: 'user', //user or group
        }).then(() => {
          CometChatWidget.on('onItemClick', args => {
            refreshChatUnreadMessageCount()
          })
        })
      }

      isChatInitialized.current = true
      isChatInitializing.current = false
    }
  }, [chatCredentials, refreshChatUnreadMessageCount])

  const handleCloseChatWidget = useCallback(async () => {
    if (!isChatInitialized.current || isChatInitializing.current) {
      return
    }

    refreshChatUnreadMessageCount()

    await CometChatWidget.logout()
  }, [refreshChatUnreadMessageCount])

  useEffect(() => {
    handleInitializeChatWidget()
  }, [handleInitializeChatWidget])

  useEffect(() => {
    return () => {
      handleCloseChatWidget()
    }
  }, [handleCloseChatWidget])

  return <div id="cometchat" className="flex-1 h-full w-full" />
}

OChatWidget.propTypes = {
  chatCredentials: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    authKey: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    widgetId: PropTypes.string.isRequired,
  }),
}
