import React, { useContext, createContext, useCallback } from 'react'

export const GaolContext = createContext({})
GaolContext.displayName = 'GaolContext'

export const GoalProvider = ({ children, onGoal, doesGoalExist }) => {
  const handleGoal = useCallback(
    data => {
      if (onGoal instanceof Function) {
        onGoal(data)
      }
    },
    [onGoal]
  )

  const handleDoesGoalExist = useCallback(
    goalTemplateId => {
      if (doesGoalExist instanceof Function) {
        return doesGoalExist(goalTemplateId)
      }

      return false
    },
    [doesGoalExist]
  )

  return (
    <GaolContext.Provider
      value={{
        onGoal: handleGoal,
        doesGoalExist: handleDoesGoalExist,
      }}
    >
      {children}
    </GaolContext.Provider>
  )
}

export const useGoalProvider = () => {
  const context = useContext(GaolContext)

  if (context === undefined) {
    throw new Error('useUser must be nested in GoalProvider')
  }

  return context
}
