import { useCallback, useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES, generateRoute } from '@const/Routes'
import { useActiveTopics, useUpdateLeaderboard, useTranslations } from '@hooks'
import { checkIfMobile } from '@utils/helpers'
import classnames from 'classnames'

import useStore from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
import usePointsStore from '@store/usePointsStore'

import { AHeading } from '@atoms/AHeading'
import { MSlider } from '@molecules/MSlider'
import { MPointsStats } from '@molecules/MPointsStats'
import { MLeaderboard } from '@molecules/MLeaderboard'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OMissionCover } from '@organisms/OMissionCover'
import { OTopicModal } from '@organisms/OTopicModal'
import { OTodaySkeleton } from '@organisms/OTodaySkeleton'

export const PToday = ({ isLoading }) => {
  const { t } = useTranslations()
  const [isMobile, setIsMobile] = useState(checkIfMobile())

  const navigate = useNavigate()

  useUpdateLeaderboard()

  const { uid } = useStore(state => ({
    uid: state.user?.id,
  }))
  const team = useStore(state => state.team)

  const userProgress = useProgressStore(state => state.userProgress)
  const getTopicsLength = useProgressStore(state => state.getTopicsLength)
  const getCompletedAssignmentsNumber = useProgressStore(
    state => state.getCompletedAssignmentsNumber
  )
  const activeTopics = useActiveTopics()

  const teamLeaderboard = usePointsStore(state => state.teamLeaderboard)
  const getUserPoints = usePointsStore(state => state.getUserPoints)

  const topicsLength = useMemo(() => {
    const progressTopicsNumber = getTopicsLength()
    const notStartedTopicsNumber = activeTopics?.reduce((acc, currVal) => {
      const topicIsStarted = !!currVal.missionsAndSeries.find(
        ({ isCompleted = false }) => isCompleted
      )

      return topicIsStarted ? acc : acc + 1
    }, 0)

    return progressTopicsNumber + notStartedTopicsNumber
  }, [activeTopics, getTopicsLength])

  const completedAssignmentsNumber = useMemo(() => {
    return getCompletedAssignmentsNumber(userProgress)
  }, [getCompletedAssignmentsNumber, userProgress])

  const userPoints = useMemo(() => {
    return getUserPoints(teamLeaderboard)
  }, [getUserPoints, teamLeaderboard])

  const handleClickLeaderboard = useCallback(() => {
    navigate(ROUTES.LEADERBOARD)
  }, [navigate])

  const handleClickMission = useCallback(
    (topicId, missionId) => {
      navigate(generateRoute(ROUTES.MISSION, { topicId, missionId }))
    },
    [navigate]
  )

  const handleResize = useCallback(() => {
    setIsMobile(checkIfMobile())
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  if (isLoading) {
    return (
      <OPageWrapper>
        <OTodaySkeleton />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper contentClassName="lg:flex lg:gap-x-10">
      <div className="flex-shrink-0 lg:flex lg:flex-col lg:w-[400px] mb-6 lg:mb-0">
        <MPointsStats
          onClick={isMobile ? handleClickLeaderboard : undefined}
          className="flex-shrink-0"
          points={userPoints}
          weeks={topicsLength}
          assignments={completedAssignmentsNumber}
        />
        <div className="hidden lg:block mt-4">
          <MLeaderboard
            teamName={team?.name}
            teamLeaderboard={teamLeaderboard}
            userId={uid}
            className="rounded-lg"
          />
        </div>
      </div>
      <div style={{ maxWidth: isMobile ? 'none' : 'calc(100% - 440px)' }}>
        {activeTopics?.map(({ id, name, missionsAndSeries }) => {
          const activeItem = missionsAndSeries.findIndex(({ isCurrent }) => isCurrent) || 0

          return (
            <div key={id}>
              <AHeading>{t('topicHeading', { topicName: name })}</AHeading>
              <MSlider
                startIndex={activeItem}
                className="mt-4 -mx-3 sm:-mx-5 lg:mx-0 mb-6 md:mb-10 overflow-x-hidden"
                sliderClassName="pr-3 lg:pr-1"
                leftPaddingPlaceholder={<div className="ml-3 sm:ml-5 lg:ml-0 -mr-1.5" />}
              >
                {missionsAndSeries.map((missionOrSeries, index) => (
                  <OMissionCover
                    key={index}
                    mission={missionOrSeries}
                    className={classnames(
                      'snap-center flex flex-shrink-0 mx-1.5 pb-4 last:mr-0',
                      activeItem === index
                        ? 'w-52 md:w-64 h-80 md:h-96'
                        : 'w-44 md:w-48 h-64 md:h-72'
                    )}
                    onClick={() => {
                      handleClickMission(id, missionOrSeries.id)
                    }}
                  />
                ))}
              </MSlider>
            </div>
          )
        })}
      </div>

      <OTopicModal />
    </OPageWrapper>
  )
}
