import React from 'react'
import PropTypes from 'prop-types'
import { getSanityImage } from '@edwin/react-sdk'

export const AChatAuthor = ({ author }) => {
  const { avatarUrl, avatarMeta, initials } = author

  const avatarSrc = getSanityImage(avatarUrl, {
    width: 150,
    height: 150,
    crop: avatarMeta?.crop,
    hotspot: avatarMeta?.hotspot,
  })

  return (
    <div className="relative flex justify-center items-center flex-shrink-0 w-7 h-7 text-gray-900 bg-gray-50 rounded-full drop-shadow-avatar overflow-hidden transform-gpu">
      {avatarUrl && (
        <img className="absolute w-full h-full object-cover" src={avatarSrc} alt="Author avatar" />
      )}
      {initials && <p className="text-sm lg:text-base">{initials}</p>}
    </div>
  )
}

AChatAuthor.propTypes = {
  author: PropTypes.object,
}
