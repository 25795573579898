import { MSkeleton } from '@molecules/MSkeleton'

export const OProgressSkeleton = () => (
  <div>
    <MSkeleton width={138} height={26} />
    <div className="max-w-sm md:max-w-3xl lg:max-w-none grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-2.5 md:gap-4 lg:gap-y-8 lg:gap-x-5 mt-4 mb-6">
      <div className="py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
      <div className="py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
      <div className="py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
      <div className="py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
      <div className="py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
      <div className="py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
      <div className="hidden md:block py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
      <div className="hidden md:block py-3 px-4 rounded-xl !rounded-tl-none shadow-md bg-white">
        <div className="flex justify-between items-center mb-2 -mr-2">
          <MSkeleton height={20} width={140} />
          <MSkeleton height={24} width={24} rounded="full" />
        </div>
        <MSkeleton height={34} width={200} />
      </div>
    </div>
  </div>
)
