import { useEffect, useState } from 'react'
import { useChatEngine } from '@edwin/react-sdk'

export const useMessages = ({
  initialMessages,
  completionMeta,
  onUserAction,
  onFinish,
  onExit,
  onProgress,
  onStepUpdated,
  onQuestion,
  triggerConfetti = () => {},
  continuousWaitFlag = true,
}) => {
  const [messages, setMessages] = useState([])

  const engineMessages = useChatEngine({
    initialMessages,
    onUserAction,
    onProgress,
    onFinish: completionMeta ? undefined : onFinish,
    onExit,
    onStepUpdated,
    onQuestion,
    continuousWaitFlag,
  })

  useEffect(() => {
    const lastEngineMessages = engineMessages[engineMessages.length - 1]

    if (lastEngineMessages) {
      setMessages(prev => [
        ...prev,
        {
          ...lastEngineMessages,
          ...(lastEngineMessages.end &&
            completionMeta && {
              onRender: () => {
                setMessages(prev => [
                  ...prev,
                  {
                    id: 'custom-end-msg',
                    renderId: 'custom-end-msg',
                    type: 'bot-component',
                    componentName: 'congratulations',
                    componentOptions: {
                      triggerConfetti,
                      completionMeta,
                      onFinish,
                    },
                  },
                ])
              },
            }),
        },
      ])
    }
  }, [completionMeta, engineMessages, onFinish, triggerConfetti])

  return messages
}
