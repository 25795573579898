import classNames from 'classnames'

import { useTranslations } from '@hooks'

import { NumericFormat } from 'react-number-format'

export const MLeaderboard = ({ teamName, teamLeaderboard, userId, className }) => {
  const { t } = useTranslations()

  return (
    <div className={classNames('mb-4 p-5 bg-white', className)}>
      {teamName && (
        <div className="mb-5 text-lg font-bold">{t('leaderboardHeading', { teamName })}</div>
      )}
      <div className="-mx-5">
        {teamLeaderboard.map(({ firstName, lastName, points, uid }, index) => (
          <div
            key={uid}
            className={classNames('py-2.5 px-5 text-sm', uid === userId && 'bg-pelorous/20')}
          >
            <div className="max-w-md flex items-center">
              <div className="w-4 mr-2 text-right">{index + 1}</div>
              <div className="flex-1">
                {firstName} {lastName}
              </div>
              <div>
                <NumericFormat
                  value={points}
                  displayType="text"
                  thousandSeparator
                  renderText={points => <div className="min-w-[44px] font-semibold">{points}</div>}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
