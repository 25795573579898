import React, { useCallback, useMemo } from 'react'

import useProgressStore from '@store/useProgressStore'
import usePointsStore from '@store/usePointsStore'

import { useTranslations } from '@hooks'

import star from '@assets/images/star.svg'
import illustration from '@assets/images/leaderboard-illustration.svg'
import bgMobile from '@assets/images/modal-background-mobile.jpg'
import bgMobilePlaceholder from '@assets/images/modal-background-mobile-placeholder.jpg'
import bgDesktop from '@assets/images/modal-background-desktop.jpg'
import bgDesktopPlaceholder from '@assets/images/modal-background-desktop-placeholder.jpg'

import ProgressiveImage from 'react-progressive-graceful-image'
import { X } from 'react-feather'
import { Dialog, Transition } from '@headlessui/react'
import { NumericFormat } from 'react-number-format'

export const OTopicModal = () => {
  const { t, translateData } = useTranslations()

  const getTopic = useProgressStore(state => state.getTopic)
  const finishedTopicsIds = useProgressStore(state => state.finishedTopicsIds)
  const removeFinishedTopic = useProgressStore(state => state.removeFinishedTopic)

  const getUserPoints = usePointsStore(state => state.getUserPoints)

  const finishedTopic = useMemo(() => {
    if (!finishedTopicsIds.length) {
      return null
    }

    const topic = getTopic(finishedTopicsIds[0])
    const translatedTopic = translateData(topic)

    return translatedTopic
  }, [finishedTopicsIds, getTopic, translateData])

  const handleCloseTopic = useCallback(
    id => {
      removeFinishedTopic(id)
    },
    [removeFinishedTopic]
  )

  return (
    <Transition show={!!finishedTopic} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => handleCloseTopic(finishedTopic.id)}
        initialFocus={false}
        open={!!finishedTopic}
      >
        <div className="flex items-center justify-center h-full min-h-[640px] md:min-h-[700px] lg:min-h-[780px] text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900/75" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative flex items-end w-full lg:max-w-2xl h-full lg:h-[740px] overflow-hidden text-left align-middle transition-all transform shadow-xl lg:rounded-xl">
              <div className="absolute top-0 inset-x-0">
                <ProgressiveImage placeholder={bgMobilePlaceholder} src={bgMobile}>
                  {src => <img className="md:hidden w-full" src={src} alt="" />}
                </ProgressiveImage>
                <ProgressiveImage placeholder={bgDesktopPlaceholder} src={bgDesktop}>
                  {src => <img className="hidden md:block w-full" src={src} alt="" />}
                </ProgressiveImage>
              </div>
              <div className="absolute top-3 right-3 z-[1]">
                <button
                  onClick={() => handleCloseTopic(finishedTopic.id)}
                  className="rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pelorous"
                >
                  <X className="h-6 w-6 text-gray-50" />
                </button>
              </div>
              {!!finishedTopic && (
                <div className="relative flex flex-col justify-between w-full h-2/3 min-h-[540px] md:min-h-[600px] lg:min-h-[540px] mt-16 pt-16 px-8 pb-14 md:py-20 lg:pt-16 lg:pb-14 text-center bg-gray-50 rounded-t-[40px]">
                  <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <img src={star} alt="" />
                  </div>
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="absolute -bottom-16 right-0 w-60 md:w-64 lg:w-60">
                      <img src={illustration} className="w-full" alt="" />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="text-2xl md:text-3xl font-bold">
                      {t('modal.congratulations')}
                    </div>
                    <div className="text-2xl font-bold">
                      {t('modal.bonusPoints', { points: finishedTopic.pointsForCompletion })}
                    </div>
                    <div className="mt-2 mb-8 md:mb-12 lg:mb-6">
                      {t('modal.completedTopic', { topicName: finishedTopic.name })}
                    </div>
                    <div className="w-36 mx-auto py-4 px-2.5 text-left bg-white rounded-lg !rounded-tl-none shadow-md">
                      <div className="text-2xl font-bold">
                        {finishedTopic?.missionsAndSeries?.length || 0}
                      </div>
                      <div className="w-10 h-0.5 my-2.5 bg-gray-900" />
                      <div>
                        {t('label.assignments', {
                          count: finishedTopic?.missionsAndSeries?.length || 0,
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="text-left md:text-center">
                    <NumericFormat
                      value={getUserPoints()}
                      displayType="text"
                      thousandSeparator
                      renderText={points => (
                        <div className="text-[50px] md:text-[80px] font-bold leading-[50px] md:leading-[80px] -tracking-[2px]">
                          {points}
                        </div>
                      )}
                    />
                    <div className="mt-1 lg:mt-0 text-xs uppercase leading-[12px] tracking-widest">
                      {t('label.totalPoints')}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
