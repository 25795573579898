import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

let pointsStore = null

export const createPointsStore = ({ sessionStorage }) => {
  if (pointsStore) return pointsStore

  pointsStore = create(
    persist(
      (set, get) => ({
        currentUserUid: null,
        teamLeaderboard: [],
        isPointsDataLoaded: false,

        setCurrentUserUid: currentUserUid => {
          set({ currentUserUid })
        },
        setTeamLeaderboard: teamLeaderboard => {
          set({ teamLeaderboard, isPointsDataLoaded: true })
        },
        getUserPoints: () => {
          const currentUserUid = get().currentUserUid
          const teamLeaderboard = get().teamLeaderboard

          if (!currentUserUid || teamLeaderboard.length === 0) {
            return 0
          }

          const userPoints =
            teamLeaderboard.find(({ uid = '' }) => uid === currentUserUid)?.points || 0

          return userPoints
        },
        updateUserPoints: newPoints => {
          const currentUserUid = get().currentUserUid
          const teamLeaderboard = get().teamLeaderboard

          if (!currentUserUid || teamLeaderboard.length === 0) {
            return
          }

          const newTeamLeaderBoard = teamLeaderboard
            .map(leaderboardItem => {
              if (leaderboardItem.uid === currentUserUid) {
                return {
                  ...leaderboardItem,
                  points: leaderboardItem.points + newPoints,
                }
              }

              return leaderboardItem
            })
            .sort((a, b) => {
              return b.points - a.points
            })

          set({ teamLeaderboard: newTeamLeaderBoard })
        },

        clearPointsStorage: () => {
          set({
            currentUserUid: null,
            teamLeaderboard: [],
            isPointsDataLoaded: false,
          })
        },
      }),
      {
        name: 'points-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )

  return pointsStore
}

export default createPointsStore
