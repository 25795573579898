import { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { ROUTES, generateRoute } from '@const/Routes'

import useProgressStore from '@store/useProgressStore'

import { useTranslations } from '@hooks'

import ReactMarkdown from 'react-markdown'
import { AIconCompleted, AIconLocked } from '@atoms/AIcons'
import { AHeading } from '@atoms/AHeading'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OProgressSkeleton } from '@organisms/OProgressSkeleton'

export const PProgress = ({ isLoading }) => {
  const { t, translateData } = useTranslations()
  const navigate = useNavigate()

  const topics = useProgressStore(state => state.userContent?.topics)
  const getTopicProgress = useProgressStore(state => state.getTopicProgress)
  const userProgress = useProgressStore(state => state.userProgress)

  const availableTopics = useMemo(() => {
    return topics?.map(topic => {
      const translatedTopic = translateData(topic)
      const topicProgress = getTopicProgress(topic.id)

      return {
        ...translatedTopic,
        ...topicProgress,
      }
    })
  }, [getTopicProgress, topics, userProgress, translateData])

  const handleClick = useCallback(
    topicId => {
      navigate(generateRoute(ROUTES.PROGRESS_TOPIC, { topicId }))
    },
    [navigate]
  )

  const calcProgressBar = useCallback(missions => {
    const topicLength = missions.length
    const completedMissionsLength = missions.filter(({ isCompleted }) => isCompleted).length
    const barWidth = 256

    return (barWidth / topicLength) * completedMissionsLength
  }, [])

  if (isLoading) {
    return (
      <OPageWrapper>
        <OProgressSkeleton />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper>
      <AHeading>{t('progressHeading')}</AHeading>
      <div className="max-w-sm md:max-w-3xl lg:max-w-none grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-2.5 md:gap-4 lg:gap-y-8 lg:gap-x-5 mt-4 mb-6">
        {availableTopics?.map(topic => {
          const isLocked = !topic.isStarted && !topic.isCompleted
          const isCurrent = topic.isStarted && !topic.isCompleted

          return (
            <button
              key={topic.id}
              onClick={() => !isLocked && handleClick(topic.id)}
              tabIndex={isLocked ? -1 : 0}
              className={classnames(
                'relative w-full h-full text-left rounded-xl !rounded-tl-none shadow-md bg-white overflow-hidden',
                'focus:outline-none focus:ring-2 focus:ring-pelorous',
                isLocked ? 'cursor-auto pointer-events-none' : 'cursor-pointer'
              )}
            >
              <div className="relative w-full h-full py-3 px-4">
                {isCurrent && (
                  <div className="absolute top-0 right-0">
                    <svg
                      width="64"
                      height="119"
                      viewBox="0 0 64 119"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.215 68.5172C20.5984 68.5236 18.9965 68.2332 17.5022 67.6631C16.0078 67.093 14.6506 66.2544 13.5091 65.1958L7.05121 59.253C4.75155 57.112 3.46094 54.217 3.46094 51.1995C3.46094 48.182 4.75155 45.2869 7.05121 43.1459L60.7387 -6.4966C62.7085 -8.32516 64.7247 -9.24658 66.7177 -9.24658C69.4677 -9.24658 72.6813 -7.33231 72.6813 -1.91806V16.9175C72.6813 22.3246 69.4446 29.546 65.3118 33.346L30.8977 65.1672C29.7606 66.2271 28.4081 67.0687 26.918 67.6436C25.4279 68.2185 23.8296 68.5154 22.215 68.5172Z"
                        fill="#39A935"
                      />
                      <path
                        d="M66.6711 118.553C65.8908 118.552 65.1184 118.408 64.3985 118.13C63.6785 117.852 63.0253 117.444 62.4766 116.931L3.59027 62.4816C1.29061 60.3406 0 57.4456 0 54.4281C0 51.4106 1.29061 48.5155 3.59027 46.3745L10.0096 40.4317C12.3253 38.3032 15.4579 37.1084 18.7232 37.1084C21.9884 37.1084 25.121 38.3032 27.4368 40.4317L63.6353 73.9029C66.507 76.5435 68.7833 79.6849 70.3321 83.145C71.8809 86.6052 72.6715 90.3152 72.6579 94.06V113.053C72.6623 113.807 72.4979 114.553 72.1748 115.245C71.8517 115.937 71.3771 116.56 70.7807 117.074C69.6661 118.038 68.1971 118.574 66.6711 118.574V118.553Z"
                        fill="#93C01F"
                      />
                    </svg>
                  </div>
                )}
                <div className="absolute top-2.5 right-2.5 flex flex-col">
                  {topic.isCompleted && <AIconCompleted className="w-5 h-5 mb-1.5 bg-citron" />}
                  {isLocked && <AIconLocked className="w-5 h-5 bg-[#EAEAEA] fill-gray-500" />}
                </div>
                <div
                  className={classnames(
                    'font-bold',
                    isCurrent ? 'text-2xl pr-12' : 'text-sm',
                    isLocked && 'opacity-50',
                    isLocked || (topic.isCompleted && 'pr-6')
                  )}
                >
                  {topic.name}
                </div>
                <ReactMarkdown
                  className={classnames(
                    isCurrent ? 'mt-1.5 mr-1 pr-12 text-lg' : 'text-sm',
                    isLocked ? 'opacity-50' : 'opacity-90'
                  )}
                >
                  {topic.description}
                </ReactMarkdown>
                {isCurrent && (
                  <div className="h-3 mt-3 mr-4 mb-2 bg-cararra rounded-xl overflow-hidden">
                    <div
                      className="h-full bg-citron"
                      style={{ width: calcProgressBar(topic.missionsAndSeries) }}
                    />
                  </div>
                )}
              </div>
            </button>
          )
        })}
      </div>
    </OPageWrapper>
  )
}
