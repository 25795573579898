import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@const/Routes'

import useStore from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
import usePointsStore from '@store/usePointsStore'

import { useTranslations } from '@hooks'

import { OPageWrapper } from '@organisms/OPageWrapper'
import { AIconArrowLeft } from '@atoms/AIcons'
import { AHeading } from '@atoms/AHeading'
import { MLeaderboardStats } from '@molecules/MLeaderboardStats'
import { MLeaderboard } from '@molecules/MLeaderboard'
import { OLeaderboardSkeleton } from '@organisms/OLeaderboardSkeleton'

export const PLeaderboard = ({ isLoading }) => {
  const { t } = useTranslations()
  const navigate = useNavigate()

  const { uid } = useStore(state => ({
    uid: state.user?.id,
  }))
  const team = useStore(state => state.team)

  const userProgress = useProgressStore(state => state.userProgress)
  const getTopicsLength = useProgressStore(state => state.getTopicsLength)
  const getCompletedAssignmentsNumber = useProgressStore(
    state => state.getCompletedAssignmentsNumber
  )
  const teamLeaderboard = usePointsStore(state => state.teamLeaderboard)
  const getUserPoints = usePointsStore(state => state.getUserPoints)

  const topicsLength = useMemo(() => {
    return getTopicsLength()
  }, [getTopicsLength, userProgress])

  const completedAssignmentsNumber = useMemo(() => {
    return getCompletedAssignmentsNumber()
  }, [getCompletedAssignmentsNumber, userProgress])

  const userPoints = useMemo(() => {
    return getUserPoints()
  }, [getUserPoints, teamLeaderboard])

  const handleClick = useCallback(() => {
    navigate(ROUTES.ROOT)
  }, [navigate])

  if (isLoading) {
    return (
      <OPageWrapper>
        <OLeaderboardSkeleton />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper>
      <div className="flex items-center mb-4">
        <button
          onClick={handleClick}
          className="flex items-center cursor-pointer rounded-sm focus:outline-none focus:ring-2 focus:ring-pelorous"
        >
          <AIconArrowLeft className="mr-2.5" />
          <AHeading className="mr-2">{t('screen.leaderboard')}</AHeading>
        </button>
      </div>
      <div className="-mx-3 sm:-mx-5 lg:-mx-8">
        <MLeaderboardStats
          points={userPoints}
          weeks={topicsLength}
          assignments={completedAssignmentsNumber}
        />
        <MLeaderboard teamName={team?.name} teamLeaderboard={teamLeaderboard} userId={uid} />
      </div>
    </OPageWrapper>
  )
}
