import { MSkeleton } from '@molecules/MSkeleton'

export const OTodaySkeleton = () => (
  <div className="lg:flex lg:gap-x-10">
    <div className="flex-shrink-0 lg:flex lg:flex-col lg:w-[400px] mb-6 lg:mb-0">
      <div className="flex lg:flex-col items-end lg:items-start mb-6 lg:mb-4 pt-14 px-8 pb-6 lg:py-9 bg-white rounded-l-lg sm:rounded-lg">
        <div>
          <MSkeleton height={50} width={100} />
          <div className="mb-1.5" />
          <MSkeleton height={12} width={60} />
        </div>
        <div className="hidden md:block">
          <div className="w-px lg:w-52 h-full lg:h-px lg:my-4 mx-10 lg:mx-0" />
        </div>
        <div className="hidden md:flex">
          <div>
            <MSkeleton height={38} width={40} />
            <div className="mb-1.5" />
            <MSkeleton height={12} width={50} />
          </div>
          <div className="ml-12">
            <MSkeleton height={38} width={40} />
            <div className="mb-1.5" />
            <MSkeleton height={12} width={90} />
          </div>
        </div>
      </div>
      <div className="hidden lg:block w-full mb-4 p-5 rounded-lg bg-white">
        <MSkeleton height={28} width={150} />
        <div className="flex justify-between items-center mt-5 py-2.5">
          <MSkeleton height={20} width={120} />
          <MSkeleton height={20} width={30} />
        </div>
        <div className="flex justify-between items-center py-2.5">
          <MSkeleton height={20} width={70} />
          <MSkeleton height={20} width={30} />
        </div>
        <div className="flex justify-between items-center py-2.5">
          <MSkeleton height={20} width={100} />
          <MSkeleton height={20} width={30} />
        </div>
        <div className="flex justify-between items-center py-2.5">
          <MSkeleton height={20} width={80} />
          <MSkeleton height={20} width={30} />
        </div>
      </div>
    </div>
    <div>
      <MSkeleton width={160} height={26} />
      <div className="flex mt-4 -mr-3 sm:-mr-5 lg:mr-0 mb-6 md:mb-10 overflow-hidden">
        {[...Array(3).keys()].map(item => (
          <div
            key={item}
            className="last:lg:hidden flex flex-col justify-between w-44 md:w-48 h-64 md:h-72 mr-2 last:mr-0 pt-2.5 px-2.5 pb-7 bg-white rounded-lg !rounded-tl-none"
          >
            <div className="ml-auto">
              <MSkeleton height={22} width={22} rounded="full" />
            </div>
            <div className="mx-auto">
              <MSkeleton height={22} width={150} />
              <div className="mt-3 mx-auto">
                <MSkeleton height={14} width={80} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)
