import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export function AButton({
  children,
  small,
  className,
  outline,
  micro,
  disabled,
  large,
  onClick,
  dontRound,
  ...props
}) {
  const cls = classNames(
    'border cursor-pointer transition-all focus:outline-none',
    small ? 'py-2 px-4' : 'py-2 px-8',
    outline
      ? 'bg-transparent border-gray-900'
      : 'text-white border-pelorous bg-pelorous focus:ring-2 focus:ring-pelorous focus:ring-offset-1 focus:text-white focus:bg-pelorous focus:border-white',
    disabled ? 'opacity-50' : 'opacity-100',
    dontRound ? 'rounded-lg' : 'rounded-full',
    className
  )

  return (
    <button className={cls} {...props} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

AButton.propTypes = {
  small: PropTypes.bool,
  className: PropTypes.string,
  outline: PropTypes.bool,
  micro: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  onClick: PropTypes.func,
  dontRound: PropTypes.bool,
}
