import { MSkeleton } from '@molecules/MSkeleton'

export const OLeaderboardSkeleton = () => (
  <div>
    <MSkeleton width={140} height={26} />
    <div className="mt-4 -mx-3 sm:-mx-5 lg:-mx-8">
      <div className="flex flex-col md:flex-row py-6 px-8 md:px-10 lg:px-7 lg:pt-8 lg:pb-10 bg-white border-b border-gray-200">
        <div className="md:py-8">
          <MSkeleton height={50} width={100} />
          <div className="mb-1.5" />
          <MSkeleton height={12} width={60} />
        </div>
        <div>
          <div className="w-52 md:w-px h-px md:h-full mt-4 md:mt-0 md:mx-14 mb-6 md:mb-0" />
        </div>
        <div className="flex mt-auto md:py-8">
          <div>
            <MSkeleton height={38} width={40} />
            <div className="mb-1.5" />
            <MSkeleton height={12} width={50} />
          </div>
          <div className="ml-12">
            <MSkeleton height={38} width={40} />
            <div className="mb-1.5" />
            <MSkeleton height={12} width={90} />
          </div>
        </div>
      </div>
      <div className="mb-4 p-5 rounded-lg bg-white">
        <div className="max-w-md">
          <MSkeleton height={28} width={150} />
          <div className="flex justify-between items-center mt-5 py-2.5">
            <MSkeleton height={20} width={120} />
            <MSkeleton height={20} width={30} />
          </div>
          <div className="flex justify-between items-center py-2.5">
            <MSkeleton height={20} width={70} />
            <MSkeleton height={20} width={30} />
          </div>
          <div className="flex justify-between items-center py-2.5">
            <MSkeleton height={20} width={100} />
            <MSkeleton height={20} width={30} />
          </div>
          <div className="flex justify-between items-center py-2.5">
            <MSkeleton height={20} width={80} />
            <MSkeleton height={20} width={30} />
          </div>
        </div>
      </div>
    </div>
  </div>
)
