export { createGoalMission } from './createGoalMission'

export const sleep = async (ms = 0) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const millisToMinutesAndSeconds = millis => {
  const minutes = Math.floor(millis / 60000)
  const seconds = ((millis % 60000) / 1000).toFixed(0)

  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

export const checkIfMobile = () => {
  return window.matchMedia('only screen and (max-width: 1023px)').matches
}
