import { useEffect } from 'react'
import { useIsOnline } from '@hooks/useIsOnline'

import useGoalsStore from '@store/useGoalsStore'

export const useGoalsQueue = () => {
  const isOnline = useIsOnline()

  const processQueue = useGoalsStore(state => state.processQueue)
  const requestsQueue = useGoalsStore(state => state.requestsQueue)

  useEffect(() => {
    if (isOnline) {
      processQueue()
    }
  }, [processQueue, requestsQueue, isOnline])
}
