import { useEffect } from 'react'
import { useIsOnline } from '@hooks/useIsOnline'

import useQuestionnaireStore from '@store/useQuestionnaireStore'

export const useQuestionnaireQueue = () => {
  const isOnline = useIsOnline()

  const processQueue = useQuestionnaireStore(state => state.processQueue)
  const requestsQueue = useQuestionnaireStore(state => state.requestsQueue)

  useEffect(() => {
    if (isOnline) {
      processQueue()
    }
  }, [processQueue, requestsQueue, isOnline])
}
