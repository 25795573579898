import { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  matchRoutes,
  useParams,
} from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as Sentry from '@sentry/react'

import { auth, retrieveTeamLeaderboardsFBFunction } from '@services/Firebase'
import { fetchChatCredentials } from '@services/Chat'
import { ROUTES, ROUTE_NAMES, getLocationRouteName } from '@const/Routes'

import { PSignIn } from '@pages/PSignIn'
import { MPastFinishedGoalsModal } from '@molecules/MPastFinishedGoalsModal'
import { OFetchDataErrorModal } from '@organisms/OFetchDataErrorModal'
import { OPageWrapper } from '@organisms/OPageWrapper'

import ANALYTICS from '@utils/constants/analytics'

import { sleep } from '@utils/helpers'

import { useStore, useEphemeralStore } from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
import useGoalsStore from '@store/useGoalsStore'
import useQuestionnaireStore from '@store/useQuestionnaireStore'
import usePointsStore from '@store/usePointsStore'
import useChatsStore from '@store/useChatStore'
import { edwinCloudApiClient } from '@services/EdwinCloudApiClient'
import { identifyUser, initializeAnalytics, trackEvent } from '@services/Analytics'

import { PToday } from '@pages/PToday'
import { PMission } from '@pages/PMission'
import { PProgress } from '@pages/PProgress'
import { PTopic } from '@pages/PTopic'
import { PChat } from '@pages/PChat'
import { PAccount } from '@pages/PAccount'
import { POnboard } from '@pages/POnboard'
import { PLeaderboard } from '@pages/PLeaderboard'

import { OLanguageModal } from '@organisms/OLanguageModal'

import {
  usePastFinishedGoals,
  useProgressQueue,
  useGoalsQueue,
  useUserQueue,
  useQuestionnaireQueue,
  useFinishingTopic,
  useTranslations,
  useUpdateTranslationLanguage,
} from '@hooks'

import CONFIG from '@config/Config'

Sentry.init({
  dsn: CONFIG.sentry.dsn,
  debug: CONFIG.sentry.debug,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigate,
        useParams,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const App = () => {
  const user = useStore(state => state.user)
  const accessToken = useStore(state => state.accessToken)
  const isUserOnboarded = useStore(state => state.user?.isOnboarded)
  const isOnboardingMissionAvailable = useProgressStore(
    state => !!Object.values(state.userContent?.onboardingMission || {})?.length
  )

  const loadUserAndOrganization = useStore(state => state.loadUserAndOrganization)
  const loadUserToken = useStore(state => state.loadUserToken)
  const addQueueTask = useStore(state => state.addQueueTask)
  const clearLocalStorage = useStore(state => state.clearLocalStorage)
  const language = useStore(state => state.language)
  const setLanguage = useStore(state => state.setLanguage)
  const newLanguage = useStore(state => state.newLanguage)
  const setNewLanguage = useStore(state => state.setNewLanguage)
  const updateUser = useStore(state => state.updateUser)
  const isAuthDataLoaded = useStore(state => state.isAuthDataLoaded)

  const clearQuestionnaireStorage = useQuestionnaireStore(state => state.clearQuestionnaireStorage)

  const [isFetchDataError, setIsFetchDataError] = useState(false)
  const updateDateNow = useEphemeralStore(state => state.updateDateNow)
  const resetEphemeralStorage = useEphemeralStore(state => state.resetStore)

  const isChatInitialized = useChatsStore(state => state.isChatInitialized)
  const initializeChat = useChatsStore(state => state.initializeChat)
  const subscribeUnreadMessageCount = useChatsStore(state => state.subscribeUnreadMessageCount)
  const clearChatStorage = useChatsStore(state => state.clearChatStorage)

  const loadRemoteContentAndProgress = useProgressStore(state => state.loadRemoteContentAndProgress)
  const clearProgressStorage = useProgressStore(state => state.clearProgressStorage)

  const loadRemoteGoalsAndInstances = useGoalsStore(state => state.loadRemoteGoalsAndInstances)
  const clearGoalsStorage = useGoalsStore(state => state.clearGoalsStorage)

  const setCurrentUserUid = usePointsStore(state => state.setCurrentUserUid)
  const setTeamLeaderboard = usePointsStore(state => state.setTeamLeaderboard)
  const clearPointsStorage = usePointsStore(state => state.clearPointsStorage)

  const [isUserDataLoading, setIsUserDataLoading] = useState(true)
  const [isEdwinCloudApiInitialized, setIsEdwinCloudApiInitialized] = useState(false)

  const { changeLanguage } = useTranslations()

  const [authUser, isAuthUserLoading] = useAuthState(auth)
  const isUserLoggedOut = !authUser && !isAuthUserLoading

  const isUserLoading = !!(
    isAuthUserLoading ||
    isUserDataLoading ||
    (!isAuthUserLoading && authUser?.uid && !user?.id)
  )

  useUpdateTranslationLanguage(newLanguage, user?.language, isAuthDataLoaded, language => {
    if (user?.id) {
      addQueueTask({ userId: user?.id, payload: { language } })
      updateUser({ language })
      setNewLanguage(null)
    }
  })

  useEffect(() => {
    if (newLanguage) {
      if (language !== newLanguage) {
        setLanguage(newLanguage)
      }
    } else if (isAuthDataLoaded && user?.language && language !== user?.language) {
      setLanguage(user.language)
    }
  }, [newLanguage, user?.language, isAuthDataLoaded, setLanguage, language])

  useProgressQueue()
  useGoalsQueue()
  useUserQueue()
  useQuestionnaireQueue()

  useFinishingTopic(isUserLoggedOut || isAuthUserLoading || isUserLoading)

  const finishedGoals = usePastFinishedGoals()

  const clearStorage = useCallback(async () => {
    useStore.persist.clearStorage()
    await useStore.persist.rehydrate()
    useProgressStore.persist.clearStorage()
    await useProgressStore.persist.rehydrate()
    useGoalsStore.persist.clearStorage()
    await useGoalsStore.persist.rehydrate()
    useQuestionnaireStore.persist.clearStorage()
    await useQuestionnaireStore.persist.rehydrate()
    usePointsStore.persist.clearStorage()
    await usePointsStore.persist.rehydrate()

    resetEphemeralStorage()
    clearLocalStorage()
    clearProgressStorage()
    clearGoalsStorage()
    clearQuestionnaireStorage()
    clearPointsStorage()
    clearChatStorage()
  }, [
    resetEphemeralStorage,
    clearLocalStorage,
    clearProgressStorage,
    clearGoalsStorage,
    clearQuestionnaireStorage,
    clearPointsStorage,
    clearChatStorage,
  ])

  const fetchData = useCallback(
    async (retryCount = 0) => {
      const MAX_RETRY_COUNT = 1

      try {
        console.log('---Should fetch', `on attempt ${retryCount + 1}`)
        if (authUser?.uid && isEdwinCloudApiInitialized) {
          console.log('---Will fetch', `on attempt ${retryCount + 1}`)

          setIsUserDataLoading(true)

          await loadUserAndOrganization(authUser.uid)

          updateDateNow()

          await loadRemoteContentAndProgress()
          await loadRemoteGoalsAndInstances()

          const { data } = await retrieveTeamLeaderboardsFBFunction()

          setCurrentUserUid(authUser.uid)
          setTeamLeaderboard(data)

          setIsUserDataLoading(false)
        }
      } catch (err) {
        await sleep(300)

        const currentUserId = auth?.currentUser?.uid
        const network = await fetch()

        // cancel retry request if no internet connection
        if (!network.isConnected) {
          console.warn('fetchData no internet connection, cancelling...')
          setIsUserDataLoading(false)
          return
        }

        // Recursively allow for a retry {MAX_RETRY_COUNT} before throwing error
        if (retryCount < MAX_RETRY_COUNT && currentUserId) {
          console.warn('fetchData error (but trying again):', err)

          retryCount++

          return fetchData(retryCount)
        }

        if (currentUserId) {
          console.warn('fetchData error final:', err)

          setIsUserDataLoading(false)
          throw new Error(err)
        }
      }
    },
    [
      authUser?.uid,
      isEdwinCloudApiInitialized,
      loadRemoteContentAndProgress,
      loadRemoteGoalsAndInstances,
      loadUserAndOrganization,
      setCurrentUserUid,
      setTeamLeaderboard,
      updateDateNow,
    ]
  )

  const initializeCometChat = useCallback(async () => {
    const { data } = await fetchChatCredentials()

    initializeChat(data)
  }, [initializeChat])

  useEffect(() => {
    if (isUserLoggedOut) {
      setIsUserDataLoading(false)
    }
  }, [isUserLoggedOut])

  useEffect(() => {
    const userId = authUser?.uid

    if (userId && isEdwinCloudApiInitialized) {
      fetchData()
        .then(() => {
          addQueueTask({ userId })
        })
        .catch(setIsFetchDataError)

      initializeCometChat().catch(() => {
        console.error('Initialize CometChat error')
      })
    }
  }, [
    addQueueTask,
    authUser?.uid,
    fetchData,
    isEdwinCloudApiInitialized,
    setIsFetchDataError,
    initializeCometChat,
  ])

  useEffect(() => {
    if (isChatInitialized) {
      subscribeUnreadMessageCount()
    }
  }, [isChatInitialized, subscribeUnreadMessageCount])

  useEffect(() => {
    if (!accessToken && authUser?.uid) {
      loadUserToken(authUser)
    }
  }, [accessToken, authUser, loadUserToken])

  useEffect(() => {
    if (accessToken) {
      edwinCloudApiClient.init(accessToken, {
        apiURL: CONFIG.cloudFunctionsUrl,
      })

      setIsEdwinCloudApiInitialized(true)
    }
  }, [accessToken])

  useEffect(() => {
    if (!authUser?.uid && !isAuthUserLoading) {
      clearStorage()
      setIsEdwinCloudApiInitialized(false)
      identifyUser(null)
    }
  }, [authUser?.uid, clearStorage, isAuthUserLoading, isEdwinCloudApiInitialized])

  useEffect(() => {
    const uid = authUser?.uid
    const organizationId = user?.organizationId
    const teamId = user?.teamId

    if (uid && organizationId && teamId) {
      identifyUser(user.id, {
        uid,
        organizationId,
        teamId,
      })

      Sentry.setUser({ uid, organizationId, teamId })
    }
  }, [authUser?.uid, user?.teamId, user?.organizationId, user?.id])

  useEffect(() => {
    trackEvent(ANALYTICS.APP_OPEN)
  }, [])

  useEffect(() => {
    if (language) changeLanguage(language)
  }, [changeLanguage, language])

  if (isAuthUserLoading) {
    return <OPageWrapper isHeaderVisible={false} />
  }

  if (isUserLoggedOut) {
    return (
      <>
        <Router>
          <Routes>
            <Route path={ROUTES.ROOT} element={<PSignIn />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Router>

        <OLanguageModal />
      </>
    )
  }

  return (
    <>
      <Router>
        <ProtectedRoutes
          isUserOnboarded={isUserOnboarded}
          isOnboardingMissionAvailable={isOnboardingMissionAvailable}
          isLoading={isAuthUserLoading || isUserLoading}
        />
      </Router>

      {isFetchDataError && (
        <OFetchDataErrorModal
          isOpen={!!isFetchDataError}
          isLoading={isUserDataLoading}
          onClose={() => {
            setIsFetchDataError(false)
          }}
          onRetry={() => {
            fetchData()
              .then(() => {
                setIsFetchDataError(false)
              })
              .catch(() => {
                setIsFetchDataError(true)
                setIsUserDataLoading(false)
              })
          }}
        />
      )}

      <OLanguageModal />

      <MPastFinishedGoalsModal finishedGoals={finishedGoals} />
    </>
  )
}

const ProtectedRoutes = ({ isUserOnboarded, isOnboardingMissionAvailable, isLoading }) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isUserOnboarded && isOnboardingMissionAvailable && !isLoading) {
      navigate(ROUTES.ONBOARD)
    }
  }, [isLoading, isOnboardingMissionAvailable, isUserOnboarded, navigate])

  useEffect(() => {
    const pageTracker = getLocationRouteName(location?.pathname)

    if (pageTracker) {
      trackEvent(ANALYTICS.VIEWED_PAGE, { name: pageTracker })
    }
  }, [location?.pathname])

  return (
    <Routes>
      <Route path={ROUTES.ROOT} element={<PToday isLoading={isLoading} />} />
      <Route path={ROUTES.LEADERBOARD} element={<PLeaderboard isLoading={isLoading} />} />
      <Route path={ROUTES.MISSION} element={<PMission isLoading={isLoading} />} />
      <Route path={ROUTES.PROGRESS} element={<PProgress isLoading={isLoading} />} />
      <Route path={ROUTES.PROGRESS_TOPIC} element={<PTopic isLoading={isLoading} />} />
      <Route path={ROUTES.PROGRESS_TOPIC_MISSION} element={<PMission isLoading={isLoading} />} />
      <Route path={ROUTES.CHAT} element={<PChat isLoading={isLoading} />} />
      <Route path={ROUTES.ACCOUNT} element={<PAccount isLoading={isLoading} />} />
      <Route path={ROUTES.ONBOARD} element={<POnboard isLoading={isLoading} />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}

ProtectedRoutes.propTypes = {
  isUserOnboarded: PropTypes.bool,
  isOnboardingMissionAvailable: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default App
