import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import illustration from '@assets/images/illustration.svg'

import { OPageHeader } from './OPageHeader'
import { ALoader } from '@atoms/ALoader'

export const OPageWrapper = ({
  children,
  isHeaderVisible = true,
  className,
  contentClassName = '',
  isLoading = false,
}) => {
  return (
    <div
      className={classnames('relative flex flex-col w-full min-h-screen bg-pampas', {
        [className]: !!className,
        'h-full': isLoading,
      })}
    >
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={illustration}
          alt=""
          className="absolute bottom-[-8px] md:bottom-[-10px] lg:bottom-[-7px] right-[-11px] md:right-[-20px] lg:right-[-11px]"
        />
      </div>

      {isHeaderVisible && <OPageHeader />}

      <div
        className={classnames(
          'relative flex-1 h-full w-full mx-auto max-w-7xl py-3.5 lg:pt-5 px-3 sm:px-5 lg:px-8 text-gray-900 z-[1]',
          contentClassName
        )}
      >
        {isLoading ? <ALoader className="h-full" /> : children}
      </div>
    </div>
  )
}
OPageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
