import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useUser } from '@edwin/react-sdk'

import { useSpring, animated } from 'react-spring'

import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatInfographicAnimatedNumber = ({
  animateNumberFrom = '0',
  animateNumberTo,
  descriptionMarkdown,
  reset = false,
}) => {
  const { user } = useUser()
  const { val } = useSpring({ val: animateNumberTo, from: { val: animateNumberFrom } })

  return (
    <div className="flex flex-col lg:flex-row lg:items-center">
      <animated.div className="self-center lg:flex-shrink-0 flex justify-center items-center lg:w-40 mt-2 mb-3 lg:my-0 lg:p-11 font-bold text-5xl">
        {val.to(val => Math.floor(val))}
      </animated.div>
      <div className="py-3 px-3.5 lg:px-5">
        <AMarkdown>{_.template(descriptionMarkdown)({ user })}</AMarkdown>
      </div>
    </div>
  )
}

MChatInfographicAnimatedNumber.propTypes = {
  descriptionMarkdown: PropTypes.string,
  animateNumberFrom: PropTypes.number,
  animateNumberTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
