import React, { useRef, useCallback } from 'react'
import classNames from 'classnames'

export const MChatUserOption = ({ label, chosenOption, index, onClick = () => {} }) => {
  const buttonRef = useRef(null)

  const handleClick = useCallback(() => {
    buttonRef.current.blur() // removing focus
    onClick()
  }, [onClick])

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      tabIndex={chosenOption === index ? -1 : 0}
      className={classNames(
        'py-3 px-3.5 lg:px-5 text-base text-left leading-[20px] border border-pelorous rounded-full transition cursor-pointer',
        'focus:outline-none focus:ring-2 focus:ring-pelorous focus:ring-offset-1 focus:text-white focus:bg-pelorous focus:border-white',
        chosenOption === null
          ? 'mb-2.5 last:mb-0 text-pelorous bg-transparent hover:text-gray-50 hover:bg-pelorous'
          : chosenOption === index
          ? 'text-gray-50 bg-pelorous animate-border-radius cursor-auto pointer-events-none'
          : 'hidden'
      )}
    >
      {label}
    </button>
  )
}
