import React, { useEffect, useState, useCallback } from 'react'

import { fetchChatCredentials } from '@services/Chat'
import useStore from '@store/useStore'

import { OPageWrapper } from '@organisms/OPageWrapper'
import { OChatWidget } from '@organisms/OChatWidget'

export const PChat = ({ isLoading }) => {
  const [chatCredentials, setChatCredentials] = useState()
  const { uid } = useStore(state => ({
    uid: state.user?.id,
  }))

  const handleSetChatCredentials = useCallback(async () => {
    try {
      const credentials = await fetchChatCredentials()
      let data = credentials.data

      setChatCredentials(data)
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    if (uid) {
      handleSetChatCredentials()
    }
  }, [handleSetChatCredentials, uid])

  return (
    <OPageWrapper className="flex flex-col h-full" isLoading={isLoading || !chatCredentials}>
      <OChatWidget chatCredentials={chatCredentials} />
    </OPageWrapper>
  )
}
