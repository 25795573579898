import { initializeAuth, browserLocalPersistence } from 'firebase/auth'
import { getFirestore, collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import firebase from 'firebase/compat/app'
import { DateTime } from 'luxon'

import CONFIG from '@config/Config'

const localTimezone = DateTime.local().zoneName

export const COLLECTIONS = {
  users: 'users',
  organizations: 'organizations',
  teams: 'teams',
  userQuestionnaires: 'userQuestionnaires',
}

export const firebaseApp = firebase.initializeApp(CONFIG.firebase)
export const firestoreDb = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp)
export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
})

if (CONFIG.connectFirebaseEmulators) {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export const updateUserData = async (userId, dataToUpdate = {}) => {
  if (!userId) {
    throw new Error('No user id provided')
  }

  const userRef = doc(firestoreDb, COLLECTIONS.users, userId)
  const userSnap = await getDoc(userRef)

  if (userSnap.exists()) {
    const { didJoin, didJoinOn, timezone, language } = userSnap.data()

    const userDataToUpdate = dataToUpdate

    if (!didJoin) {
      userDataToUpdate.didJoin = true
    }

    if (!didJoinOn) {
      userDataToUpdate.didJoinOn = new Date()
    }

    if (localTimezone !== timezone && localTimezone) {
      userDataToUpdate.timezone = localTimezone
    }

    if (language === userDataToUpdate.language) {
      delete userDataToUpdate.language
    }

    const hasUserDataChanged = Object.keys(userDataToUpdate).length

    if (hasUserDataChanged) {
      await updateDoc(userRef, userDataToUpdate)
    }
  }
}

export const handleUserTimezone = async userId => {}

export const doesUserExistFBFunction = httpsCallable(functions, 'doesUserExist')
export const retrieveTeamLeaderboardsFBFunction = httpsCallable(
  functions,
  'retrieveTeamLeaderboards'
)
export const retrieveChatCredentials = httpsCallable(functions, 'retrieveChatCredentials')

export const usersCollectionRef = collection(firestoreDb, COLLECTIONS.users)
