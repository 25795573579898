import React from 'react'

export const MChatMsgError = ({ componentName }) => (
  <div className="py-3.5 px-4">
    Component name: <strong>{componentName}</strong>
    <hr className="mt-1 mb-2.5" />
    <div className="mb-1.5 text-red-500 text-center font-semibold">
      Component not found in config
      <span className="block text-xs">(check components config or msg object)</span>
    </div>
  </div>
)
