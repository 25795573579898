import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useTranslations } from '@hooks'

import illustrationBig from '@assets/images/leaderboard-illustration.svg'

import { NumericFormat } from 'react-number-format'
import { SectionLabel } from '@molecules/MPointsStats'

export const MLeaderboardStats = ({
  points = 0,
  assignments = 0,
  weeks = 0,
  className,
  onClick,
}) => {
  const { t } = useTranslations()
  const SPACING_CLASSES = 'md:py-8'

  return (
    <div
      onClick={onClick}
      className={classNames(
        'relative py-6 px-8 md:px-10 lg:px-7 lg:pt-8 lg:pb-10 text-gray-50 bg-tuatara overflow-hidden',
        className
      )}
    >
      <div className="absolute top-0 right-0">
        <img src={illustrationBig} alt="illustration" />
      </div>
      <div className="relative flex flex-col md:flex-row">
        <div className={classNames('md:min-w-[130px]', SPACING_CLASSES)}>
          <NumericFormat
            value={points}
            displayType="text"
            thousandSeparator
            renderText={points => (
              <div className="text-[50px] font-bold leading-[50px] -tracking-[2px]">{points}</div>
            )}
          />
          <SectionLabel label={t('label.points')} />
        </div>
        <div className="block">
          <div className="w-52 md:w-px h-px md:h-full mt-4 md:mt-0 md:mx-10 mb-6 md:mb-0 bg-white" />
        </div>

        <div className={classNames('flex mt-auto', SPACING_CLASSES)}>
          <div>
            <span className="text-4xl font-bold -tracking-[2px]">{weeks}</span>
            <SectionLabel label={t('label.weeks', { count: weeks })} />
          </div>
          <div className="ml-10">
            <span className="text-4xl font-bold -tracking-[2px]">{assignments}</span>
            <SectionLabel label={t('label.assignments', { count: assignments })} />
          </div>
        </div>
      </div>
    </div>
  )
}

MLeaderboardStats.propTypes = {
  points: PropTypes.number,
  assessments: PropTypes.number,
  weeks: PropTypes.number,
}
