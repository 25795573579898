export const MChatMissionSummaryMedal = ({}) => (
  <svg width="67" height="115" viewBox="0 0 67 115" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_43_2478)">
      <path
        d="M2.81177 7.68116C2.81177 3.99126 5.80303 1 9.49293 1H46.4092C50.0991 1 53.0903 3.99126 53.0903 7.68117V8.25138C53.0903 11.6264 50.3544 14.3623 46.9794 14.3623V7.34144H8.92272V14.3623C5.54774 14.3623 2.81177 11.6264 2.81177 8.25138V7.68116Z"
        fill="#FFEEB0"
      />
      <path
        d="M2.81177 8.52272C2.81177 4.83281 5.80303 1.84155 9.49293 1.84155H46.4092C50.0991 1.84155 53.0903 4.83281 53.0903 8.52272V9.09293C53.0903 12.4679 50.3544 15.2039 46.9794 15.2039V8.183H8.92272V15.2039C5.54774 15.2039 2.81177 12.4679 2.81177 9.09293V8.52272Z"
        fill="#F4CC3F"
      />
      <path
        d="M8.92688 8.10498H46.9755V35.0506L27.9512 48.3983L8.92688 35.0506V8.10498Z"
        fill="#3EA1C9"
      />
      <mask
        id="mask0_43_2478"
        // eslint-disable-next-line react/style-prop-object
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="9"
        y="8"
        width="39"
        height="41"
      >
        <path
          d="M9.00732 8.10498H47.0384V35.055L28.0229 48.3983L9.00732 35.055V8.10498Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_43_2478)">
        <rect x="20.7402" y="-7.88904" width="14.5651" height="56.2873" fill="#366380" />
      </g>
      <rect x="24.7804" y="41.3345" width="6.11496" height="10.4181" rx="3.05748" fill="#C09525" />
      <circle cx="27.8379" cy="76.1621" r="26.8379" fill="url(#paint0_linear_43_2478)" />
      <circle cx="27.8381" cy="76.1622" r="20.9494" fill="#705100" />
      <mask
        id="mask1_43_2478"
        // eslint-disable-next-line react/style-prop-object
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="8"
        y="57"
        width="43"
        height="43"
      >
        <circle cx="29.106" cy="78.5507" r="20.9982" fill="#C28B37" />
      </mask>
      <g mask="url(#mask1_43_2478)">
        <circle cx="27.8376" cy="76.1622" r="20.9982" fill="#E3A504" />
      </g>
      <circle cx="28.1215" cy="76.3128" r="17.2504" fill="#C18222" />
      <path
        d="M27.8379 66.4845L31.0585 72.5678L37.4996 73.3282L33.0767 78.0123L34.279 84.7343L27.8379 81.6926L21.3968 84.7343L22.6099 78.0123L18.1763 73.3282L24.6174 72.5678L27.8379 66.4845Z"
        fill="url(#paint1_linear_43_2478)"
      />
      <path
        d="M28.2798 66.2506L27.8379 65.4159L27.396 66.2506L24.2982 72.102L18.1176 72.8316L17.1284 72.9484L17.8131 73.6719L22.0729 78.1723L20.9048 84.6455L20.7323 85.601L21.6103 85.1864L27.8379 82.2456L34.0655 85.1864L34.9418 85.6002L34.7712 84.6462L33.6132 78.1723L37.8631 73.6714L38.546 72.9482L37.5582 72.8316L31.3776 72.102L28.2798 66.2506Z"
        stroke="#A36D1D"
        strokeOpacity="0.2"
      />
      <path
        opacity="0.5"
        d="M47.1595 24.9822L8.87203 24.9822L8.87203 8.1525L47.1595 8.1525L47.1595 24.9822Z"
        fill="url(#paint2_linear_43_2478)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_43_2478"
        x="0.216502"
        y="0.216502"
        width="66.2117"
        height="114.536"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5.48449" dy="5.48449" />
        <feGaussianBlur stdDeviation="3.13399" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_43_2478" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_43_2478" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_43_2478"
        x1="27.8379"
        y1="49.3242"
        x2="27.8379"
        y2="103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE176" />
        <stop offset="1" stopColor="#FFD12D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_43_2478"
        x1="27.8379"
        y1="66.4845"
        x2="27.8379"
        y2="84.7343"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.0001" stopColor="#FFFFFD" />
        <stop offset="1" stopColor="#FFE86D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_43_2478"
        x1="28.0158"
        y1="24.9822"
        x2="28.0158"
        y2="8.1525"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3EA1C9" stopOpacity="0.02" />
        <stop offset="1" stopColor="#052C31" />
      </linearGradient>
    </defs>
  </svg>
)
