import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useTranslations } from '@hooks'

import illustration from '@assets/images/points-illustration.svg'
import illustrationBig from '@assets/images/leaderboard-illustration.svg'

import { NumericFormat } from 'react-number-format'

export const SectionLabel = ({ label }) => (
  <div className="mt-1 lg:mt-0 text-xs uppercase leading-[12px] tracking-widest">{label}</div>
)

export const MPointsStats = ({ points = 0, assignments = 0, weeks = 0, className, onClick }) => {
  const { t } = useTranslations()
  const SPACING_CLASSES = 'pt-6 pb-4 lg:p-0'

  return (
    <button
      onClick={onClick}
      tabIndex={onClick ? 0 : -1}
      className={classNames(
        'relative text-left w-full py-6 px-8 md:px-10 lg:px-7 lg:pt-8 lg:pb-10 text-gray-50 rounded-l-lg sm:rounded-lg bg-tuatara overflow-hidden',
        'focus:outline-none focus:ring-2 focus:ring-pelorous',
        onClick ? 'cursor-pointer' : 'cursor-auto pointer-events-none',
        className
      )}
    >
      <div className="absolute top-0 right-0">
        <img className="lg:hidden" src={illustration} alt="illustration" />
        <img className="hidden lg:block" src={illustrationBig} alt="illustration" />
      </div>
      <div className="relative flex lg:flex-col">
        <div className={classNames('md:min-w-[130px]', SPACING_CLASSES)}>
          <NumericFormat
            value={points}
            displayType="text"
            thousandSeparator
            renderText={points => (
              <div className="text-[50px] font-bold leading-[50px] -tracking-[2px]">{points}</div>
            )}
          />
          <SectionLabel label={t('label.points')} />
        </div>
        <div className="hidden md:block">
          <div className="w-px lg:w-52 h-full lg:h-px lg:mt-4 mx-10 lg:mx-0 lg:mb-6 bg-white" />
        </div>

        <div className={classNames('hidden md:flex mt-auto', SPACING_CLASSES)}>
          <div>
            <span className="text-4xl font-bold -tracking-[2px]">{weeks}</span>
            <SectionLabel label={t('label.weeks', { count: weeks })} />
          </div>
          <div className="ml-12">
            <span className="text-4xl font-bold -tracking-[2px]">{assignments}</span>
            <SectionLabel label={t('label.assignments', { count: assignments })} />
          </div>
        </div>
      </div>
    </button>
  )
}

MPointsStats.propTypes = {
  points: PropTypes.number,
  assessments: PropTypes.number,
  weeks: PropTypes.number,
}
