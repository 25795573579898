import { useCallback, useEffect } from 'react'

import useProgressStore from '@store/useProgressStore'
import usePointsStore from '@store/usePointsStore'

import { retrieveTeamLeaderboardsFBFunction } from '@services/Firebase'

export const useUpdateLeaderboard = () => {
  const isQueueProcessing = useProgressStore(state => state.isQueueProcessing)
  const requestsQueue = useProgressStore(state => state.requestsQueue)

  const teamLeaderboard = usePointsStore(state => state.teamLeaderboard)
  const setTeamLeaderboard = usePointsStore(state => state.setTeamLeaderboard)

  const updateTeamLeaderboard = useCallback(async () => {
    try {
      const progressQueueIsEmpty = !requestsQueue.length

      if (progressQueueIsEmpty) {
        const { data } = await retrieveTeamLeaderboardsFBFunction()

        setTeamLeaderboard(data)

        return 'Team leaderboard updated'
      }
    } catch (err) {
      console.error('Updating leaderboard error: ', err)
    }
  }, [requestsQueue, setTeamLeaderboard])

  useEffect(() => {
    if (!isQueueProcessing && teamLeaderboard.length) {
      updateTeamLeaderboard().then(status => {
        if (status) {
          console.log(status)
        }
      })
    }
  }, [isQueueProcessing, teamLeaderboard.length, updateTeamLeaderboard])

  return null
}
