const CONFIG = {
  firebase: {
    apiKey: 'AIzaSyC5kwiMFI8ZvUm5EWnaMxzBRo_2ckf3pn8',
    authDomain: 'max-sports-health.firebaseapp.com',
    projectId: 'max-sports-health',
    storageBucket: 'max-sports-health.appspot.com',
    messagingSenderId: '1039181657445',
    appId: '1:1039181657445:web:6707281c9f3db94cb65d8c',
  },
  sentry: {
    dsn: 'https://ea2d25bb0cf54bf898c709d2e717c3f6@o1365081.ingest.sentry.io/6677366',
    debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  },
  mixpanel: {
    token: 'fa142bd010256fd9efa85d8e25136320',
  },
  analyticsEnabled: true,
  cloudFunctionsUrl: 'https://api.edwin.cloud/v1',
  connectFirebaseEmulators: false,
}

export default CONFIG
