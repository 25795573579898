import { MSkeleton } from '@molecules/MSkeleton'

export const OMissionSkeleton = () => (
  <div className="flex h-full">
    <div className="hidden lg:block w-[300px]">
      <div className="fixed top-0 w-[300px] h-screen text-gray-50 bg-pelorous shadow-xl overflow-hidden">
        <MSkeleton width={300} height={288} rounded="none" />
        <div className="p-7">
          <div className="mb-2">
            <MSkeleton width={220} height={28} />
          </div>
          <div className="mb-5">
            <MSkeleton width={140} height={24} />
          </div>
          <div className="mt-5 pt-2.5 mb-3">
            <MSkeleton width={100} height={16} />
          </div>
        </div>
      </div>
    </div>
    <div className="flex-1 max-w-3xl mx-auto pt-20 px-2.5 lg:pt-8">
      <div className="flex pb-2">
        <MSkeleton width={28} height={28} rounded="full" />
        <div className="ml-2">
          <MSkeleton width={220} height={32} />
        </div>
      </div>
      <div className="flex pb-2">
        <div className="ml-9">
          <MSkeleton width={160} height={32} />
        </div>
      </div>
      <div className="flex flex-row-reverse pb-2">
        <MSkeleton width={28} height={28} rounded="full" />
        <div className="mr-2">
          <MSkeleton width={130} height={32} rounded="full" />
        </div>
      </div>
    </div>
    <div className="lg:hidden fixed top-0 inset-x-0">
      <div className="flex items-center py-4 px-3 bg-white shadow-md">
        <MSkeleton width={200} height={20} />
      </div>
    </div>
  </div>
)
