import { useCallback, useEffect, useRef } from 'react'

import useProgressStore from '@store/useProgressStore'
import usePointsStore from '@store/usePointsStore'

export const useFinishingTopic = isAppNotReady => {
  const userProgress = useProgressStore(state => state.userProgress)
  const getTopic = useProgressStore(state => state.getTopic)
  const getTopicProgress = useProgressStore(state => state.getTopicProgress)
  const setFinishedTopic = useProgressStore(state => state.setFinishedTopic)

  const updateUserPoints = usePointsStore(state => state.updateUserPoints)

  const prevFinishedTopics = useRef(null)

  const getFinishedTopics = useCallback(() => {
    const finishedTopics = Object.keys(userProgress)
      .map(topicId => {
        return getTopicProgress(topicId)
      })
      .filter(({ isCompleted }) => isCompleted)
      .map(({ id }) => id)

    return finishedTopics
  }, [getTopicProgress, userProgress])

  const handleInitialState = useCallback(() => {
    prevFinishedTopics.current = getFinishedTopics()
  }, [getFinishedTopics])

  const handleUserProgressChange = useCallback(
    props => {
      const finishedTopics = getFinishedTopics()

      const newFinishedTopics = finishedTopics.filter(
        topicId => !prevFinishedTopics.current.includes(topicId)
      )

      newFinishedTopics.forEach(topicId => {
        const topicPoints = getTopic(topicId).pointsForCompletion || 0

        updateUserPoints(topicPoints)

        setFinishedTopic(topicId)
        prevFinishedTopics.current.push(topicId)
      })
    },
    [getFinishedTopics, getTopic, setFinishedTopic, updateUserPoints]
  )

  const handleFinishingTopic = useCallback(
    props => {
      if (isAppNotReady) {
        return (prevFinishedTopics.current = null)
      }

      if (prevFinishedTopics.current) {
        return handleUserProgressChange(userProgress)
      }

      handleInitialState()
    },
    [handleInitialState, handleUserProgressChange, isAppNotReady, userProgress]
  )

  useEffect(() => {
    handleFinishingTopic(userProgress)
  }, [handleFinishingTopic, userProgress])
}
