import React, { useCallback, useRef, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import classnames from 'classnames'

import Vimeo from '@u-wave/react-vimeo'

const DESKTOP_WIDTH_START = 678

export const MChatVideo = ({
  src,
  type,
  padding = 0,
  onProceed = trigger => {
    console.log('MChatVideo called onProceed')
  },
}) => {
  const playerRef = useRef()
  const [onProceedCalled, setOnProceedCalled] = useState(false)
  const [alreadyRequestedFullscreen, setAlreadyRequestedFullscreen] = useState(false)

  const handleOnReady = useCallback(player => {
    playerRef.current = player
  }, [])

  const [width] = useWindowSize()

  const handleOnPlay = useCallback(() => {
    if (width < DESKTOP_WIDTH_START && !alreadyRequestedFullscreen) {
      playerRef.current.requestFullscreen()
      setAlreadyRequestedFullscreen(true)
    }

    if (!onProceedCalled) {
      setTimeout(() => {
        onProceed()
        setOnProceedCalled(true)
      }, 1000)
    }
  }, [onProceed, onProceedCalled, alreadyRequestedFullscreen, width])

  return (
    <div
      className={classnames('w-64 lg:w-96', type === 'vimeo' && 'relative')}
      style={{ paddingBottom: `${padding}%` }}
    >
      {type === 'vimeo' && (
        <Vimeo
          video={src}
          onPlay={handleOnPlay}
          onReady={handleOnReady}
          volume={1}
          color="6D6BDC"
          className={classnames(
            'w-full',
            padding ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' : 'h-full'
          )}
          responsive
        />
      )}
      {type === 'youtube' && (
        <iframe
          className="w-full"
          src={src}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      )}
      {type === 'unknown' && (
        <div className="py-6 text-red-500 text-center">Error: Video is not supported</div>
      )}
    </div>
  )
}
