import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut as firebaseSignOut,
} from 'firebase/auth'
import { auth } from '@services/Firebase'
import { trackEvent } from '@services/Analytics'
import ANALYTICS from '@utils/constants/analytics'

export const signIn = async ({ email, password }) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    await trackEvent(ANALYTICS.SIGN_IN)
  } catch (err) {
    await trackEvent(ANALYTICS.SIGN_IN_UNSUCCESSFUL)
    return Promise.reject(err)
  }
}

export const signOut = async () => {
  await firebaseSignOut(auth)
  await trackEvent(ANALYTICS.SIGN_OUT)
}

export const resetPassword = async email => {
  await sendPasswordResetEmail(auth, email)
}
