import { Fragment, useCallback } from 'react'
import classnames from 'classnames'

import { useTranslations } from '@hooks'

import useStore from '@store/useStore'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export const MLanguageSelect = () => {
  const { languages, currentLanguage } = useTranslations()

  const setNewLanguage = useStore(state => state.setNewLanguage)

  const handleLanguageChange = useCallback(
    languageCode => {
      setNewLanguage(languageCode)
    },
    [setNewLanguage]
  )

  return (
    <Listbox value={currentLanguage.name} onChange={handleLanguageChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-pelorous sm:text-sm sm:leading-6">
              <span className="block truncate">{currentLanguage.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {languages.map(language => (
                  <Listbox.Option
                    key={language.name}
                    className={({ active }) =>
                      classnames(
                        active ? 'bg-pelorous text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4'
                      )
                    }
                    value={language.code}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classnames(
                            language.code === currentLanguage.code
                              ? 'font-semibold'
                              : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {language.name}
                        </span>

                        {language.code === currentLanguage.code ? (
                          <span
                            className={classnames(
                              active ? 'text-white' : 'text-pelorous',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
